import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditMemberDetails from "../forms/EditDetailsofMember";
import Addmember from "../forms/Addmember";
import { useSelector } from "react-redux";

const Team = () => {
  const [editMemberData, setEditMemberData] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [editmember, seteditmember] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);

  let memberData = useSelector((state) => state.profileSlice.profile);
  memberData = memberData?.data?.members || [];

  const handleEditClick = (member) => {
    setEditMemberData(member);
    seteditmember(true);
  };

  return (
    <>
      {/* <EditMemberDetails show={editmember} onHide={() => { seteditmember(false); }} member={editMemberData} /> */}
      <EditMemberDetails
        show={editmember}
        onHide={() => {
          seteditmember(false);
        }}
        member={editMemberData}
      />
      <Addmember
        show={addMemberModal}
        onHide={() => {
          setAddMemberModal(false);
        }}
      />

      <div className="container mt-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center my-3">
            <button
              className="btn btn-primary bg-theme z-14"
              onClick={() => {
                setAddMemberModal(true);
              }}
            >
              <i className="fas fa-plus"></i> Add Member
            </button>
            <Link to="/profile" className="btn btn-primary bg-theme z-14">
              <i className="fas fa-arrow-left"></i> Back
            </Link>
          </div>
        </div>

        <div className="row">
          {memberData.map((member) => (
            <div key={member.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="our-team mt-5">
                <button
                  className="btn btn-primary bg-theme btn-sm position-absolute top-0 end-0 m-2 edit-btn"
                  onClick={() => handleEditClick(member)}
                >
                  <i className="fas fa-pen"></i>
                </button>
                <div className="picture">
                  <img
                    className="img-fluid"
                    src={`${member.member_logo}`}
                    alt="Profile"
                    onError={(e) =>
                      (e.target.src =
                        "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png")
                    }
                  />
                </div>
                <div className="team-content mt-3">
                  <h6 className="name">{member.name}</h6>
                  <p>{member.member_job_title}</p>
                  <p>
                    <i className="fas fa-envelope text-theme"></i>{" "}
                    {member.email}
                  </p>
                  <p>
                    <i className="fas fa-phone text-theme"></i>{" "}
                    {member.country_code?.split(":")[1].trim() +
                      " " +
                      member.contact}
                  </p>
                </div>
                <div className="team-content">
                  <button className="btn btn-primary btn-sm bg-theme">
                    Chat
                  </button>
                </div>
                <ul className="social">
                  <li>
                    <a
                      href={"mailto:" + member.email}
                      className="fas fa-envelope"
                      aria-hidden="true"
                    ></a>
                  </li>
                  <li>
                    <a
                      href={"tel:" + member.country_code + member.contact}
                      className="fas fa-address-book"
                      aria-hidden="true"
                    ></a>
                  </li>
                  <li>
                    <a
                      href={"https://wa.me/" + member.contact}
                      className="fab fa-whatsapp"
                      aria-hidden="true"
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Team;
