import { createSlice } from "@reduxjs/toolkit";
import { 
  ProfileInsert ,
  Profileget ,
  Profilelist, 
  SecondaryInsert,
  memberlistForNavbar,
  getMemberDetailsByCompanyId,
  updateMemberDetails,
  fetchUsers ,
  profileUpdate,
  MemberInsert,
  updateCompanyDetails,
  BranchAdd,
  ServiceUpdate,
  bannerUpdate,
  BranchUpdate,
  BranchDelete} from "../api/ProfileApi";

const profileSlice = createSlice({
    name: "profileSlice",
    initialState: {
      loading: false,
      insert: null,
      profile: null,
      profileList:[],
      MemberList:[],
      MemberDetails:[],
      Testing:[],
      

    },
    reducers:{
        // signup_details :(state, action)=>{
        // state.signup_detail="";
        // console.log(action.payload, " authslice",state);
        // },

        // Login_detail :(state, action)=>{
        //   state.Login_detail="";
        //   console.log(action.payload, " authslice",state);
        //   }

    },

    extraReducers: (builder) => {
      
      builder.addCase(ProfileInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(ProfileInsert.fulfilled,  (state, action) => {
       state.insert=action.payload;
        state.loading = false
       
      })
      builder.addCase(ProfileInsert.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

        // profile get api
      builder.addCase(Profileget.pending, state => {
         state.loading = true
       })
       builder.addCase(Profileget.fulfilled,  (state, action) => {
        state.profile=action.payload;
         state.loading = false
        
       })
       builder.addCase(Profileget.rejected, (state,action) => {
         console.log("rejected")
         state.loading = false
       })


       // profile list api
      builder.addCase(Profilelist.pending, state => {
        state.loading = true
      })
      builder.addCase(Profilelist.fulfilled,  (state, action) => {
        state.profileList=action.payload.data;
        state.loading = false
       
      })
      builder.addCase(Profilelist.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

      // Secondary Insert api
      builder.addCase(SecondaryInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(SecondaryInsert.fulfilled,  (state, action) => {
        state.loading = false
       
      })
      builder.addCase(SecondaryInsert.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })


            // updateMemberDetails api
            builder.addCase(updateMemberDetails.pending, state => {
              state.loading = true
            })
            builder.addCase(updateMemberDetails.fulfilled,  (state, action) => {
              state.loading = false
             
            })
            builder.addCase(updateMemberDetails.rejected, (state,action) => {
              console.log("rejected")
              state.loading = false
            })
            
      
       
        // getMemberDetailsByCompanyId list api
        builder.addCase(getMemberDetailsByCompanyId.pending, state => {
          state.loading = true
        })
        builder.addCase(getMemberDetailsByCompanyId.fulfilled,  (state, action) => {
          state.MemberDetails=action.payload.data;
          console.log(state.MemberDetails,"state.MemberList");
          
          state.loading = false
         
        })
        builder.addCase(getMemberDetailsByCompanyId.rejected, (state,action) => {
          console.log("rejected")
          state.loading = false
        })

       // Navbar list api
       builder.addCase(memberlistForNavbar.pending, state => {
        state.loading = true
      })
      builder.addCase(memberlistForNavbar.fulfilled,  (state, action) => {
        state.MemberList=action.payload.data;
        console.log(state.MemberList,"state.MemberList");
        
        state.loading = false
       
      })
      builder.addCase(memberlistForNavbar.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })


      // node js api uses slice
      builder.addCase(fetchUsers.pending, state => {
        state.loading = true
      })
      builder.addCase(fetchUsers.fulfilled,  (state, action) => {
        state.Testing=action.payload.data;
       
        
        state.loading = false
       
      })
      builder.addCase(fetchUsers.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })
     
      // node api for member profile update--
      builder.addCase(profileUpdate.pending, state => {
        state.loading = true
      })
      builder.addCase(profileUpdate.fulfilled,  (state, action) => {
        state.loading = false
       
      })
      builder.addCase(profileUpdate.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

       // node api for member profile Insert--
       builder.addCase(MemberInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(MemberInsert.fulfilled,  (state, action) => {
        state.loading = false
       
      })
      builder.addCase(MemberInsert.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

         // node api for company profile update--
         builder.addCase(updateCompanyDetails.pending, state => {
          state.loading = true
        })
        builder.addCase(updateCompanyDetails.fulfilled,  (state, action) => {
          state.loading = false
         
        })
        builder.addCase(updateCompanyDetails.rejected, (state,action) => {
          console.log("rejected")
          state.loading = false
        })

          // node api for company branch update--
          builder.addCase(BranchUpdate.pending, state => {
            state.loading = true
          })
          builder.addCase(BranchUpdate.fulfilled,  (state, action) => {
            state.loading = false
           
          })
          builder.addCase(BranchUpdate.rejected, (state,action) => {
            console.log("rejected")
            state.loading = false
          })

            // node api for company service update--
         builder.addCase(ServiceUpdate.pending, state => {
          state.loading = true
        })
        builder.addCase(ServiceUpdate.fulfilled,  (state, action) => {
          state.loading = false
         
        })
        builder.addCase(ServiceUpdate.rejected, (state,action) => {
          console.log("rejected")
          state.loading = false
        })

          // node api for company branch add--
          builder.addCase(BranchAdd.pending, state => {
            state.loading = true
          })
          builder.addCase(BranchAdd.fulfilled,  (state, action) => {
            state.loading = false
           
          })
          builder.addCase(BranchAdd.rejected, (state,action) => {
            console.log("rejected")
            state.loading = false
          })

           // node api for bannerUpdate--
           builder.addCase(bannerUpdate.pending, state => {
            state.loading = true
          })
          builder.addCase(bannerUpdate.fulfilled,  (state, action) => {
            state.loading = false
           
          })
          builder.addCase(bannerUpdate.rejected, (state,action) => {
            console.log("rejected")
            state.loading = false
          })

           // node api for BranchDelete--
           builder.addCase(BranchDelete.pending, state => {
            state.loading = true
          })
          builder.addCase(BranchDelete.fulfilled,  (state, action) => {
            state.loading = false
           
          })
          builder.addCase(BranchDelete.rejected, (state,action) => {
            console.log("rejected")
            state.loading = false
          })
    
    }
  })

  export default profileSlice.reducer;