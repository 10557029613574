import { createSlice } from "@reduxjs/toolkit";
import { CommunityAll } from "../api/CommunityApi";

const communitySlice = createSlice({

    name: "CommunitySlice",
    initialState: {
        loading: false,
        community:'',
    },
    reducers:{
      
    },

    extraReducers: (builder) => {

      
      
      //  CommunityAll----
      builder.addCase(CommunityAll.pending, state => {
        state.loading = true
      })
      builder.addCase(CommunityAll.fulfilled,  (state, action) => {
       state.community=action.payload;
       console.log(state.Community,"state.Community communitySlice")
        state.loading = false
      })
  
      builder.addCase(CommunityAll.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

    }
  })
  export default communitySlice.reducer;