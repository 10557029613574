import { createSlice } from "@reduxjs/toolkit";
import { NewsApi } from "../api/NewsApi";

const profileSlice = createSlice({
    name: "newsSlice",
    initialState: {
      loading: false,
      news: null,

    },
    reducers:{
        // signup_details :(state, action)=>{
        // state.signup_detail="";
        // console.log(action.payload, " authslice",state);
        // },

        // Login_detail :(state, action)=>{
        //   state.Login_detail="";
        //   console.log(action.payload, " authslice",state);
        //   }

    },

    extraReducers: (builder) => {
      builder.addCase(NewsApi.pending, state => {
       // console.log("Pending....");
        state.loading = true
      })
      builder.addCase(NewsApi.fulfilled,  (state, action) => {
       state.news=action.payload;
        state.loading = false
       // let config = action.payload['member_data']
       
      })
      builder.addCase(NewsApi.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
        // Alert.alert("Info", config['message']);
      })

   

    
    }
  })

  //Actions
//   export const { signup_details } = authSlice.actions;
  
  //Reducer
  export default profileSlice.reducer;