import Modal from 'react-bootstrap/Modal';
import React, { useState, useRef } from "react";
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { Profileget } from "../apis/api/ProfileApi";
import { bannerUpdate } from '../apis/api/ProfileApi';
import Loading from "../hooks/Loading";
import { toast } from "react-toastify";

function UploadBanner(props) {

  const dispatch = useDispatch();
 
  
  const [loading, setLoading] = useState(false);

  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileFlag, setFileFlag] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const [cannotUpload, setCannotUpload] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [file, setFile] = useState(null); 

  const fileInputRef = useRef(null);
  const uploadedFileRef = useRef(null);

  const isAdvancedUpload = (function () {
    const div = document.createElement("div");
    return (
      ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
      "FormData" in window &&
      "FileReader" in window
    );
  })() || false;

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    setFileSize((selectedFile.size / 1024).toFixed(1) + " KB");
    uploadedFileRef.current.style.display = "flex";
    setProgressWidth(0);
    setFileFlag(0);

  
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result); 
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile); 
    }
  };

  const handleUploadClick = async () => {
    if (file) {
      if (fileFlag === 0) {
        setFileFlag(1);
        setLoading(true)
        const formData = new FormData();
        const company_id = Cookies.get("company_id");
    
        formData.append('file', file);
        formData.append('company_id', company_id);
        const response= await dispatch(bannerUpdate(formData));

        if(response.payload.status=='success'){
          dispatch(Profileget({ company_id: company_id }));
          setLoading(false);
          handleModalClose();
          props.onHide();
          toast.success("profile Upload Successfull!");
        }else{
          setLoading(false);
          handleModalClose();
          props.onHide();
          toast.success("profile Upload Failed!");
        }
       
      }
    } else {
      setCannotUpload(true);
    }
  };

  const handleCancelAlert = () => {
    setCannotUpload(false);
  };

  const handleRemoveFile = () => {
    uploadedFileRef.current.style.display = "none";
    fileInputRef.current.value = "";
    setFile(null); 
    setFileName("");
    setFileSize("");
    setFileFlag(0);
    setProgressWidth(0);
    setImagePreviewUrl(null);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    fileInputRef.current.files = files;
    handleFileChange({ target: { files: files } });
  };

  const handleModalClose = () => {
    setImagePreviewUrl(null);
    setFileName("");
    setFileSize("");
    fileInputRef.current.value = ""; 
    setFile(null); 
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        handleModalClose(); 
        props.onHide();    
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Upload Banner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Loading loading={loading} />
        <form className="form-container" encType="multipart/form-data">
          <div className="upload-files-container">
            <div
              className={`drag-file-area ${imagePreviewUrl ? "d-none" : ""}`}
              onDrop={isAdvancedUpload ? handleDrop : undefined}
              onDragOver={(e) => e.preventDefault()}
            >
              <h5 className="dynamic-message">Drag & drop any file here</h5>
              <label className="label me-5 w-100">
                or
                <span className="browse-files">
                  <input
                    type="file"
                    className="default-file-input"
                    ref={fileInputRef}
                    onClick={() => (fileInputRef.current.value = "")}
                    onChange={handleFileChange}
                  />
                  <span className="browse-files-text">browse file</span>
                  <span>from device</span>
                </span>
              </label>
            </div>
            {cannotUpload && (
              <span className="cannot-upload-message">
                <span className="material-icons-outlined">error</span>
                Please select a file first
                <span
                  className="material-icons-outlined cancel-alert-button"
                  onClick={handleCancelAlert}
                >
                  cancel
                </span>
              </span>
            )}

            {/* Image Preview */}
            {imagePreviewUrl && (
              <div className="image-preview-container mt-3">
                <h5>Image Preview:</h5>
                <img 
                  src={imagePreviewUrl} 
                  alt="Image preview" 
                  className="image-preview" 
                  height="200"  /* Set your desired height */
                />
              </div>
            )}

            <div className="file-block" ref={uploadedFileRef} style={{ display: "none" }}>
              <div className="file-info">
                <span className="material-icons-outlined file-icon">description</span>
                <span className="file-name">{fileName}</span> |{" "}
                <span className="file-size">{fileSize}</span>
              </div>
              <span
                className="material-icons remove-file-icon ms-4"
                onClick={handleRemoveFile}
              >
                delete
              </span>
              <div className="progress-bar" style={{ width: `${progressWidth}px` }}></div>
            </div>

            <button type="button" className="upload-button" onClick={handleUploadClick}>
              Upload
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default UploadBanner;

