import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL,NODE_URL} from "./Config";

export const ProfileInsert = createAsyncThunk(
    "post/ProfileInsert",
    async (data) => { 
        let url = BASE_URL+"profile/insert";
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
       
            return Promise.reject(error)
        }
    }
)

export const Profileget = createAsyncThunk(
    "post/Profileget",
    async (data) => { 
        let url = NODE_URL+"profileGet";
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const Profilelist = createAsyncThunk(
    "post/Profilelist",
    async (data) => { 
        let url = BASE_URL+"profile/list";
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
          
            return Promise.reject(error)
        }
    }
)


export const SecondaryInsert = createAsyncThunk(
    "post/SecondaryInsert",
    async (data) => { 
        let url = BASE_URL+"profile/SecondaryInsert";
        try{
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)
export const getMemberDetailsByCompanyId = createAsyncThunk(
    "post/getMemberDetailsByCompanyId",
    async (data) => { 
        let url = BASE_URL+"profile/getMemberDetailsByCompanyId";
        try{
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)
export const memberlistForNavbar = createAsyncThunk(
    "post/memberlistForNavbar",
    async (data) => { 
        let url = BASE_URL+"profile/memberlistForNavbar";
        try{
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const updateMemberDetails = createAsyncThunk(
    "post/updateMemberDetails",
    async (data) => { 
        let url = NODE_URL+"updateMemberDetails";
        try{
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)



export const fetchUsers = createAsyncThunk(
    "get/fetchUsers",
    async () => { 
        let url = "https://clubnet.app/node/api/users";
        try{
            const response = (await axios.get(url)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const profileUpdate = createAsyncThunk(
    "post/profileUpdate",
    async (data) => { 
        let url = NODE_URL+"profileUpdate";
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const bannerUpdate = createAsyncThunk(
    "post/bannerUpdate",
    async (data) => { 
        let url = NODE_URL+"bannerUpdate";
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const updateCompanyDetails = createAsyncThunk(
    "post/updateCompanyDetails",
    async (data) => { 
        let url = NODE_URL+"updateCompanyDetails";
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const MemberInsert = createAsyncThunk(
    "post/MemberInsert",
    async (data) => { 
        let url = NODE_URL+"MemberInsert";
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const ServiceUpdate = createAsyncThunk(
    "post/ServiceUpdate",
    async (data) => { 
        let url = NODE_URL+"ServiceUpdate";
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const BranchAdd = createAsyncThunk(
    "post/BranchAdd",
    async (data) => { 
        let url = NODE_URL+"BranchAdd";
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)



export const BranchUpdate = createAsyncThunk(
    "post/BranchUpdate",
    async (data) => { 
        let url = NODE_URL+"BranchUpdate";
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const BranchDelete = createAsyncThunk(
    "post/BranchDelete",
    async (data) => { 
        let url = NODE_URL+"BranchDelete";
        try{
            const response = (await axios.delete(url+'/'+data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)
