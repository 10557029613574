import { createSlice } from "@reduxjs/toolkit";
import { galleryInsert ,galleryGet } from "../api/GalleryApi";

const gallerySlice = createSlice({
    name: "gallerySlice",
    initialState: {
      loading: false,
      gallery: null,

    },
    reducers:{
        // signup_details :(state, action)=>{
        // state.signup_detail="";
        // console.log(action.payload, " authslice",state);
        // },

        // Login_detail :(state, action)=>{
        //   state.Login_detail="";
        //   console.log(action.payload, " authslice",state);
        //   }

    },

    extraReducers: (builder) => {

        // gallery insert slice
      builder.addCase(galleryInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(galleryInsert.fulfilled,  (state, action) => {
        state.loading = false
       
      })
      builder.addCase(galleryInsert.rejected, (state,action) => {
        state.loading = false
      })

       // gallery get slice
       builder.addCase(galleryGet.pending, state => {
        state.loading = true
      })
      builder.addCase(galleryGet.fulfilled,  (state, action) => {
        state.loading = false
        state.gallery=action.payload;
      })
      builder.addCase(galleryGet.rejected, (state,action) => {
        state.loading = false
      })

   

    
    }
  })


  export default gallerySlice.reducer;