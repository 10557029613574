import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import useFormValidation from "../hooks/useFormValidation";
import { useSelector, useDispatch } from "react-redux";
import { statelist, countrylist, countrycode } from "../apis/api/DataApi";
import { BranchAdd } from "../apis/api/ProfileApi";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import Loading from "../hooks/Loading";
import { useForm, Controller } from "react-hook-form";
import { Profileget } from "../apis/api/ProfileApi";

const BranchDetailsModal = ({ showModal, closeModal }) => {
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const loading = useSelector((state) => state.profileSlice.loading);



  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { handleChange } = useFormValidation({});

  useEffect(() => {
    dispatch(countrylist());
    dispatch(countrycode());
    dispatch(statelist());
  }, [dispatch]);

  const { country_list, state_list, country_code } = useSelector(
    (state) => state.dataSlice
  );

  const countryOptions = country_list?.data || [];
  const stateOptions = state_list?.data || [];
  const countryCodeOptions = country_code?.data || [];

  useEffect(() => {
    if (selectedCountry) {
      setStates(stateOptions[selectedCountry]);
    } else {
      setStates([]);
    }
  }, [selectedCountry, stateOptions]);

  const handleCountryChange = (selectedOption, label) => {
    setSelectedCountry(selectedOption.value);
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };

  const handleStateChange = (selectedOption, label) => {
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };

  const onSubmitHandler = async (formData) => {

    const data = new FormData();
    const company_id = Cookies.get("company_id");

    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object" && formData[key] !== null) {
        if (Array.isArray(formData[key])) {
          formData[key].forEach((item, index) => {
            if (item.value) {
              data.append(`${key}[${index}]`, item.value);
            } else {
              data.append(`${key}[${index}]`, item);
            }
          });
        } else {
          if (key === "company" && formData[key].country) {
            data.append("country", formData[key].country.value);
          } else if (key === "Company" && formData[key].state) {
            data.append("state", formData[key].state.value);
          } else if (formData[key]?.value) {
            data.append(key, formData[key].value);
          } else {
            Object.keys(formData[key]).forEach((subKey) => {
              const subItem = formData[key][subKey];
              if (subItem.value) {
                data.append(`${key}[${subKey}]`, subItem.value);
              }
            });
          }
        }
      } else {
        data.append(key, formData[key]);
      }
    });
    data.append("company_id", company_id);

    const response = await dispatch(BranchAdd(data));
    if (response?.payload?.status === "success") {

      const profile=await dispatch(Profileget({ company_id: company_id }))
      if(profile.payload.status=='success'){
        toast.success("Branch Details Updated Successfully!");
        closeModal();
        reset(); 
      }
    } else {
      toast.error("Failed to Update Branch Details.");
    }
    
   
    
  };

  const handleClose = () => {
    reset();
    closeModal(); 
    
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-bold">
            Add New Branch
            <span className="form-title no-border"></span>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading} />
        <div className="row mx-1">
          <form
            method="post"
            onSubmit={handleSubmit(onSubmitHandler)}
            encType="multipart/form-data"
          >
            <div className="form-container">
              <div className="d-block border rounded my-2 px-2 py-0">
                <div className="position-relative text-left">
                  <span className="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                    <span className="text-bold">
                      Branch Information
                      <span className="form-title no-border"></span>
                    </span>
                  </span>
                  <div className="row">
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Country Name <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="company[country]"
                            control={control}
                            rules={{ required: "Country is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={countryOptions}
                                onChange={(selectedOption) => {
                                  if (selectedOption) {
                                    field.onChange(selectedOption);
                                    handleCountryChange(selectedOption);
                                  } else {
                                    field.onChange(null);
                                  }
                                }}
                                placeholder={"Select the country"}
                              />
                            )}
                          />
                          {errors.company?.country && (
                            <span className="text-danger">
                              {errors.company.country.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* State Name */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            State Name <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="Company[state]"
                            control={control}
                            rules={{ required: "State is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={states}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  handleStateChange(
                                    selectedOption,
                                    "Company[state]"
                                  );
                                }}
                                placeholder={"select the state"}
                              />
                            )}
                          />
                          {errors.Company?.state && (
                            <span className="text-danger">
                              {errors.Company.state.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* City Name */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            City Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="City Name"
                            {...register("CityName", {
                              required: "City Name is required",
                            })}
                          />
                          {errors.CityName && (
                            <span className="text-danger">
                              {errors.CityName.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Employee Strength */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Employee Strength{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Employee Strength"
                            {...register("EmployeeStrength", {
                              required: "Employee Strength is required",
                            })}
                          />
                          {errors.EmployeeStrength && (
                            <span className="text-danger">
                              {errors.EmployeeStrength.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Contact Number */}
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="form-label">
                            Contact Number{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex">
                            <Controller
                              name="countryCode"
                              control={control}
                              rules={{ required: "Country code is required" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={countryCodeOptions}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    handleChange({
                                      target: {
                                        name: "countryCode",
                                        value: selectedOption.value,
                                      },
                                    });
                                  }}
                                  placeholder={"Code"}
                                  className="me-2"
                                  styles={{
                                    container: (provided) => ({
                                      ...provided,
                                      width: "100px",
                                    }),
                                  }}
                                />
                              )}
                            />
                            {errors.countryCode && (
                              <span className="text-danger">
                                {errors.countryCode.message}
                              </span>
                            )}

                            {/* Contact Number Input */}
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Contact Number"
                              {...register("ContactNumber", {
                                required: "Contact Number is required",
                              })}
                            />
                          </div>
                          {errors.ContactNumber && (
                            <span className="text-danger">
                              {errors.ContactNumber.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Address */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Branch Address{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Enter Address"
                            rows="3"
                            {...register("address", {
                              required: "Branch Address is required",
                            })}
                          />
                           {errors.address && (
                            <span className="text-danger">
                              {errors.address.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-secondary bg-theme-color my-2 px-4"
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BranchDetailsModal;
