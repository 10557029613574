import React, { useState, useEffect } from 'react';

const FileInput = ({ name, label, handleChange, defaultUrl }) => {
  const [preview, setPreview] = useState(defaultUrl || null); // Use defaultUrl for the initial preview

  // Handle file input change
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const filePreview = URL.createObjectURL(file);
      setPreview(filePreview);
      handleChange({ target: { name, value: file } });
      showCrossIcon();
    }
  };

  // Handle URL input change
  const onUrlChange = (e) => {
    const url = e.target.value;
    setPreview(url);
    handleChange({ target: { name, value: url } });
    showCrossIcon();
  };

  // Reset the input and preview
  const handleReset = () => {
    setPreview(null);
    handleChange({ target: { name, value: null } });
    hideCrossIcon();
  };

  // Open file dialog for selecting files
  const handleOpenFileDialog = () => {
    document.getElementById(name).click();
  };

  // Show and hide cross icon functions
  const showCrossIcon = () => {
    const crossIcon = document.getElementById(`cross-${name}`);
    if (crossIcon) {
      crossIcon.classList.remove('d-none');
    }
  };

  const hideCrossIcon = () => {
    const crossIcon = document.getElementById(`cross-${name}`);
    if (crossIcon) {
      crossIcon.classList.add('d-none');
    }
  };

  useEffect(() => {
    if (defaultUrl) {
      setPreview(defaultUrl);
    }
  }, [defaultUrl]);

  return (
    <div className="file-input-with-preview">
      <label className="form-label">{label}</label>
      <div className="input-group">
        {/* Hidden file input */}
        <input
          type="file"
          className="form-control"
          id={name}
          name={name}
          onChange={onFileChange}
          style={{ display: 'none' }}
        />

        {/* Display the URL or file name */}
        <input
          type="text"
          className="form-control"
          placeholder="No File Chosen or Enter URL"
          value={preview ? preview?.split('/').pop() : ''}
          onChange={onUrlChange}
          onClick={handleOpenFileDialog}
          readOnly={!preview}
        />
        
        {/* Cross and Upload buttons */}
        <div className="input-group-append">
          <span className="input-group-text input-reset d-none" id={`cross-${name}`} onClick={handleReset}>
            <i className="fa-solid fa-times"></i>
          </span>
          <span className="input-group-text input-click" onClick={handleOpenFileDialog}>
            <i className="fa-solid fa-upload"></i>
          </span>
        </div>
      </div>

      {/* Preview Image */}
      {preview && (
        <div className="image-preview mt-2">
          <img src={preview} alt={`${name} preview`} style={{ maxWidth: '200px', maxHeight: '200px' }} />
        </div>
      )}
    </div>
  );
};

export default FileInput;
