import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { useLocation } from "react-router-dom";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FILE_URL } from "../apis/api/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const ActiveMemberTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const location = useLocation();
  const { activeMemberData, recent, active } = location.state || {};



  useEffect(() => {
    if (activeMemberData) {
      const filteredData = activeMemberData.filter((customer) =>
        customer.member_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProfiles(filteredData);
    }
  }, [searchQuery, activeMemberData]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectionChange = (e) => {
    setSelectedProfiles(e.value);
  };

  const nameBodyTemplate = (rowData) => (
    <div className="d-flex align-items-center">
      <img
        src={`${FILE_URL}/${rowData?.member_logo || "default-logo.png"}`}
        alt={rowData.member_name}
        style={{
          width: "30px",
          height: "30px",
          marginRight: "10px",
          borderRadius: "50%",
        }}
      />
      {rowData.member_name}
    </div>
  );

  const activityBodyTemplate = () => (
    <div className=" me-2">
      <button className="btn btn-primary me-1 p-0">
        <FontAwesomeIcon icon={faEye} className="text-white p-1" />
      </button>
    </div>
  );

  const handleRowsChange = (e) => {
    setRowsPerPage(Number(e.target.value));
  };

  return (
    <div className="datatable-crud-demo container-fluid ">
      <h5 className="mb-2 mt-3 text-color  d-flex align-items-center">
        <i className="fas fa-users me-1"></i>
        <span>{recent && <span>{recent}</span>}</span>
        <span>{active && <span>{active}</span>}</span>
      </h5>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <div className="input-group" style={{ maxWidth: "300px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search by name..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="btn btn-primary bg-theme">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <select
          className="form-select ms-2"
          value={rowsPerPage}
          onChange={handleRowsChange}
          style={{ width: "100px" }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>

      <DataTable
        value={filteredProfiles}
        paginator
        rows={rowsPerPage}
        emptyMessage="No members found."
        rowClassName={() => "custom-row"}
        selection={selectedProfiles}
        onSelectionChange={onSelectionChange}
        selectionMode="checkbox"
      >
               <Column
          selectionMode="multiple"
          headerClassName="bg-theme-color me-2"
          headerStyle={{
            width: "2rem",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "30px" }}
        ></Column>

        <Column
          body={nameBodyTemplate}
          header="Name"
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="company_name"
          header="Company"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="company_country"
          header="Country"
          body={(rowData) => rowData.company_country || ""}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        {/* <Column
          field="member_plan"
          header="Membership Plan"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        /> */}

        {/* <Column
          field="membership_start"
          header="Start Date"
          body={(rowData) =>
            new Date(rowData.membership_start).toLocaleDateString()
          }
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        /> */}

        <Column
          field="status"
          header="Status"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="activity"
          header="Activity"
          body={activityBodyTemplate}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
        />
      </DataTable>
    </div>
  );
};

export default ActiveMemberTable;
