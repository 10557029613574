import React from 'react';
import { SyncLoader } from 'react-spinners';


const Loading = (props) => {
    const { loading } = props;

    return (
        <div className={`loading-container ${loading ? 'active' : ''}`}>
            <div className="loader">
            <SyncLoader color="rgba(47, 171, 224, 0.87)" />

               
        
            </div>
        </div>
    );
};

export default Loading;