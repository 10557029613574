import { createSlice } from "@reduxjs/toolkit";
import { countrylist,statelist,membershipPlan,countrycode,membershipPlanlist ,services} from "../api/DataApi";

const dataSlice = createSlice({

    name: "dataSlice",
    initialState: {
      loading: false,
      country_list:'',
      state_list:'',
      memberplan_list:'',
      Plan_List:'',
      country_code:'',
      membershipPlanList:'',
      service:"",
    },
    reducers:{
        // signup_details :(state, action)=>{
        // state.signup_detail="";
        // console.log(action.payload, " authslice",state);
        // },

        // Login_detail :(state, action)=>{
        //   state.Login_detail="";
        //   console.log(action.payload, " authslice",state);
        //   }

    },

    extraReducers: (builder) => {
      builder.addCase(countrylist.pending, state => {
        state.loading = true
      })
      builder.addCase(countrylist.fulfilled,  (state, action) => {
       state.country_list=action.payload;
        state.loading = false
      })
      builder.addCase(countrylist.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

      builder.addCase(statelist.pending, state => {
         state.loading = true
       })
       builder.addCase(statelist.fulfilled,  (state, action) => {
        state.state_list=action.payload;
         state.loading = false
       })
       builder.addCase(statelist.rejected, (state,action) => {
         console.log("rejected")
         state.loading = false
       })

       builder.addCase(membershipPlan.pending, state => {
        state.loading = true
      })
      builder.addCase(membershipPlan.fulfilled,  (state, action) => {
       state.memberplan_list=action.payload;
        state.loading = false
      })
      builder.addCase(membershipPlan.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })


      builder.addCase(countrycode.pending, state => {
        state.loading = true
      })
      builder.addCase(countrycode.fulfilled,  (state, action) => {
       state.country_code=action.payload;
        state.loading = false
      })
      builder.addCase(countrycode.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

      builder.addCase(membershipPlanlist.pending, state => {
        state.loading = true
      })
      builder.addCase(membershipPlanlist.fulfilled,  (state, action) => {
       state.membershipPlanList=action.payload;
        state.loading = false
      })
      builder.addCase(membershipPlanlist.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

      // api for service get---------
      builder.addCase(services.pending, state => {
        state.loading = true
      })
      builder.addCase(services.fulfilled,  (state, action) => {
       state.loading = false
       state.service = action.payload;
      })
      builder.addCase(services.rejected, (state,action) => {
        state.loading = false
      })

    
    }
  })

  //Actions
//   export const { signup_details } = authSlice.actions;
  
  //Reducer
  export default dataSlice.reducer;