import React, { useState, useEffect } from 'react';


import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Profileget } from "../apis/api/ProfileApi";
import { useParams } from 'react-router-dom';

const NavSidebar = ({ children }) => {
 

  return (
    <>
      
      <div className="container-fluid">
        <div className="row row1">
          <div className="col-md-3 ">
          
          </div>
          <div className="col-md-9 margin-top MainComponent">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavSidebar;
