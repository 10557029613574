import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FILE_URL } from "../apis/api/Config";
import { Profileget } from "../apis/api/ProfileApi";
import { useNavigate, Link } from "react-router-dom";
import AboutDetails from "../forms/AboutDetails";
import EditMemberDetails from "../forms/EditDetailsofMember";
import Albums from "../album/Album";

import Timelines from "../timeline/TimelineforMembers";
import ServicesForm from "../forms/Editservices";
import UploadProfile from "../forms/UploadProfile";
import UploadBanner from "../forms/UploadBanner";
import IntroEdit from "../forms/CompanyIntroEdit";
import Cookies from "js-cookie";


export const convertServices = (data) => {
  return data.map((item) => {
    const basicServices = item.basic_service_names?.split(",").map((service) => {
      const [name, id] = service.split(":");
      return { name: name.trim(), id: id.trim() };
    });

    const specialServices = item.special_service_names
      .split(",")
      .map((service) => {
        const [name, id] = service?.split(":");
        return { name: name.trim(), id: id.trim() };
      });

    return {
      basic_services: basicServices,
      special_services: specialServices,
    };
  });
};

function MemberDetails() {
  const fallbackImage = "https://s17.postimg.cc/ypm5ye95r/image.jpg";
  const fallbackImage2 =
    "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [member, setMember] = useState();
  const [Services, setServices] = useState([]);
  const [branch, setbranch] = useState([]);
  const [activeTab, setActiveTab] = useState("about");
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [Location, setLocation] = useState([]);
  
  const id = Cookies.get("company_id");
  let memberData = useSelector((state) => state.profileSlice.profile);

  useEffect(() => {
    if (memberData) {
      let Service_data = memberData?.data.services;
      if (Service_data.length > 0) {
        Service_data = convertServices(memberData?.data.services);
        setServices(Service_data[0]);
      }
      setMember(memberData?.data);
      setbranch(memberData?.data.company_branch);
      setLocation(memberData?.data.company_branch);
    }
  }, [memberData]);
  useEffect(() => {
    fetchData();
  }, [id]);


  const fetchData = async () => {
    dispatch(Profileget({ company_id: id }));
  };

  const handleError = (e) => {
    e.target.src = fallbackImage;
  };
  const handleError1 = (e) => {
    e.target.src = fallbackImage2;
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleNavigate = () => {
    navigate("/Team");
  };

  const handleButtonClick = (index) => {
    setSelectedLocation(index);
  };

  const [modalShow, seteditShow] = React.useState(false);
  const [uploadshow, setuploadshow] = React.useState(false);
  const [uploadBanner, setuploadBanner] = React.useState(false);
  const [aboutshow, setaboutshow] = React.useState(false);
  const [uploadAlbum, setuploadAlbum] = React.useState(false);
  const [companyIntro, setcompanyIntro] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const convertServices = (data) => {
    return data.map((item) => {
      // Convert basic services
      const basicServices = item.basic_service_names
        .split(",")
        .map((service) => {
          const [name, id] = service?.split(":");
          return { name: name.trim(), id: id.trim() }; // Create an object with name and id
        });

      // Convert special services
      const specialServices = item.special_service_names
        .split(",")
        .map((service) => {
          const [name, id] = service.split(":");
          return { name: name.trim(), id: id.trim() }; // Create an object with name and id
        });

      return {
        basic_services: basicServices,
        special_services: specialServices,
      };
    });
  };

  return (
    <>
      <EditMemberDetails show={modalShow} onHide={() => seteditShow(false)} />
      <UploadProfile show={uploadshow} onHide={() => setuploadshow(false)} />
      <UploadBanner show={uploadBanner} onHide={() => setuploadBanner(false)} />
      <AboutDetails show={aboutshow} onHide={() => setaboutshow(false)} />
      <IntroEdit
        show={companyIntro}
        onHide={() => setcompanyIntro(false)}
        member={member?.company}
      />
      <ServicesForm
        data={Services}
        showModal={showModal}
        handleClose={handleClose}
      />
      {memberData && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 parent-container ">
              <div className="container-fluid mt-4 bg-light parent">
                <div className="row">
                  <div className="col-12">
                    <div className="banner-container position-relative mb-4">
                      <img
                        src={`${member?.company?.[0]?.company_banner}`.replace(
                          "/thumb/",
                          "/"
                        )}
                        className="img-fluid banner-logo w-100"
                        onError={handleError}
                      />

                      <button
                        className="btn btn-secondary btn-sm camera-btn"
                        onClick={() => setuploadBanner(true)}
                      >
                        <i className="fas fa-camera"></i>
                      </button>

                      <div className="ms-4 profile Profile">
                        <img
                          src={
                            member?.company?.[0]?.company_logo ||
                            "default-logo.png"
                          }
                          className="rounded-circle"
                          alt="Company Logo"
                          onError={handleError1}
                        />
                        <button
                          className="btn btn-secondary btn-sm  mtN-53 "
                          onClick={() => setuploadshow(true)}
                        >
                          <i className="fas fa-camera"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <h3 className="profile-name text-theme ms-13">
                    {member?.company?.[0]?.company_name}
                  </h3>
                  <p className="profile-title text-muted ms-13">
                    <a
                      href={member?.company?.[0]?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none me-2"
                    >
                      {member?.company?.[0]?.website}
                    </a>
                    | {member?.company?.[0]?.contact}
                  </p>
                  <p className="profile-title text-muted ms-13 me-2">
                    {member?.company?.[0]?.company_name},
                    {member?.company?.[0]?.address},
                    {member?.company?.[0]?.country}.
                  </p>
                </div>

                <div className="form-row mt-5">
                  <div className="form-group col-md-12">
                    <ul className="nav nav-tabs d-flex flex-row flex-wrap justify-content-start">
                      <li
                        className="i.border-member{
tem"
                      >
                        <button
                          className={`nav-link button-collapse custom-button ${
                            activeTab === "about" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("about")}
                        >
                          About
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Photo Tab */}
                {activeTab === "photo" && (
                  <div className="container mt-3 mb-5">
                    <div class="d-flex flex-row justify-content-between"></div>

                    <Albums />
                  </div>
                )}
                {activeTab === "timeline" && (
                  <div className="container mt-3 mb-5 ">
                    <Timelines />
                  </div>
                )}

                {/* About Tab */}
                {activeTab === "about" && (
                  <div className=" row d-flex justify-content-between">
                    <div className="form-row col-12 col-md-5 z-14 child">
                      <div className="intro w-100">
                        <section class="container container1 my-5">
                          <div class="row align-items-center">
                            <div class="container my-2 ">
                              <div class="col-md-12 d-flex align-items-center justify-content-between mb-4">
                                <div class="d-flex align-items-center">
                                  <i class="fas fa-globe me-1 ms-2"></i>
                                  <h5 class="fw-bold text-theme mb-0">Intro</h5>
                                </div>
                                <button
                                  class="btn btn-outline-secondary bg-theme-color btn-sm"
                                  onClick={() => setcompanyIntro(true)}
                                >
                                  <i class="fas fa-edit"></i> Edit
                                </button>
                              </div>

                              <div class="row justify-content-center text-center">
                                <div>
                                  <p class="lead text-muted fs-6 mb-4">
                                    {member?.company?.[0]?.bio}
                                  </p>
                                  <hr />
                                  <div class="d-flex justify-content-center">
                                    <ul class="list-unstyled text-start f">
                                      <li class="mb-2 fs-6">
                                        <i class="fas fa-map-marker-alt me-2 text-theme"></i>
                                        {member?.company?.[0]?.address}
                                      </li>
                                      <li class="mb-2 fs-6">
                                        Membership plan :{" "}
                                        {member?.company?.[0]?.member_plan
                                          .split(":")[1]
                                          .trim()}
                                      </li>
                                      <li class="mb-2 fs-6">
                                        Tax Registration :{" "}
                                        {member?.company?.[0]?.tax_registration}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className="container  mb-3 container1 w-100">
                        <div classname="mt-3">
                          <h4 classname="text-theme mt-5">Media</h4>
                        </div>
                        <div className="row mb-4 mt-3">
                          <Albums />
                        </div>
                      </div>

                      <div className="profile-tab-item-section profile-tab-item-section-about bg-white w-100">
                        <div className="container1  custom-border-radius mb-3">
                          <div className="main-blue f16 weight600 vmiddle text-md black ml-3 d-flex  justify-content-evenly">
                            {member?.company_country} {Location.length} offices
                          </div>
                          <div className="d-flex justify-content-end">
                            {/* {company_id === userid && ( */}
                            <Link
                              to="/about"
                              className="btn-primary bg-theme pe-2 ps-2 mb-3  me-2 text-decoration-none "
                              style={{ borderRadius: "3px" }}
                            >
                              Edit
                            </Link>

                            {/* )} */}
                          </div>

                          <div className="d-flex flex-wrap mb-5 ms-2">
                            {branch.map((location, index) => (
                              <button
                                key={index}
                                className={`btn btn-primary btn-sm bg-theme me-3 ${
                                  selectedLocation === index ? "active" : ""
                                }`}
                                onClick={() => handleButtonClick(index)}
                              >
                                {location.country?.split(':').filter(Boolean)[1]}
                              </button>
                            ))}
                          </div>
                          {Location.length > 0 && (
                            <div className="container  mt-5 mb-3">
                              <div className="row">
                                <div className="col-12">
                                  <div className="contact-info bg-white p-3">
                                    <p>
                                      <strong className="text-theme">
                                        Address:
                                      </strong>{" "}
                                      {Location[selectedLocation].address}
                                    </p>
                                    <p>
                                      <strong className="text-theme">
                                        Contact:
                                      </strong>{" "}
                                      {Location[selectedLocation].contact}
                                    </p>
                                  </div>
                                </div>

                                {/* <div className="col-12">
                                <div className="map mt-3">
                                  <iframe
                                    src={locations[selectedLocation].mapSrc}
                                    width="100%"
                                    height="250"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                  ></iframe>
                                </div>
                              </div> */}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="container1 custom-border-radius mb-3">
                          <div className="col-12 ">
                            <h5 className="text-theme mt-2 ms-2">
                              Manage Teams
                            </h5>
                            {/* {company_id == userid && ( */}
                            <button
                              className="btn btn-primary bg-theme btn-sm mt-3 ms-2"
                              onClick={() => handleNavigate()}
                            >
                              <i className="fas fa-pen"></i> Edit
                            </button>
                            {/* )} */}

                            <div className="container">
                              <div className="row">
                                {member?.members.map((member) => (
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-6">
                                    <div className="our-team mt-2 border-member position-relative hover-card custom-border-radius">
                                      <div className="picture">
                                        <img
                                          className="img-fluid"
                                          src={member.member_logo}
                                          alt="Profile"
                                          onError={handleError1}
                                        />
                                      </div>
                                      <div className="team-content mt-1">
                                        <h6 className="name">{member.name}</h6>
                                      </div>

                                      <ul className="social">
                                        <li>
                                          <a
                                            href={"mailto:" + member.email}
                                            className="fas fa-envelope"
                                            aria-hidden="true"
                                          ></a>
                                        </li>
                                        <li>
                                          <a
                                            href={
                                              "tel:" +
                                              member.country_code +
                                              member.contact
                                            }
                                            className="fas fa-address-book"
                                            aria-hidden="true"
                                          ></a>
                                        </li>
                                        <li>
                                          <a
                                            href={
                                              "https://wa.me/" + member.contact
                                            }
                                            className="fab fa-whatsapp"
                                            aria-hidden="true"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          ></a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-5 mb-3 position-relative">
                          <button
                            className="btn btn-primary bg-theme btn-sm position-absolute top-0 end-0 m-2 edit-btn"
                            onClick={handleShow}
                          >
                            <i className="fas fa-pen"></i>
                          </button>

                          <h4 className="mb-4">Services</h4>
                          <div className="container">
                            <div className="row">
                              {Array.isArray(Services.basic_services) &&
                                Services.basic_services.map((service) => (
                                  <div
                                    className="col-md-6 mb-3 ms-4"
                                    key={service.id}
                                  >
                                    <div className="service-box d-flex align-items-center">
                                      <span className="dot bg-primary me-2"></span>
                                      <p className="mb-0">{service.name}</p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>

                          <h4 className="mb-4 mt-5">Special Services</h4>
                          <div className="container">
                            <div className="row">
                              {Array.isArray(Services.special_services) &&
                                Services.special_services.map((service) => (
                                  <div
                                    className="col-md-6 mb-3 ms-4"
                                    key={service.id}
                                  >
                                    <div className="service-box d-flex align-items-center">
                                      <span className="dot bg-primary me-2"></span>
                                      <p className="mb-0">{service.name}</p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row col-12  col-md-6 child ">
                      <Timelines  />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Video Tab */}
          {activeTab === "video" && <p>video</p>}
        </div>
      )}
    </>
  );
}

export default MemberDetails;
