import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL} from "./Config";

export const NewsApi = createAsyncThunk(
    "get/NewsApi",
    async () => { 
        let url = BASE_URL+"news/detail";
        try{
            
            const response = (await axios.get(url)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)


