import AboutDetails from '../forms/AboutDetails';
import BranchDetails from '../forms/AddNewBranch';
import React, { useState, useEffect } from "react";
import { BranchDelete } from '../apis/api/ProfileApi';
import { useDispatch,useSelector } from 'react-redux';
import showConfirmation from '../hooks/showConfirmation';
import { Profileget } from "../apis/api/ProfileApi";
import Cookies from "js-cookie";
import Loading from "../hooks/Loading"; 
import { toast } from "react-toastify";

function About() {
  const dispatch=useDispatch();

  const [aboutshow, setaboutshow] = React.useState(false);
  const [branch, setbranch] = React.useState(false);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const [Location, setLocation] = useState([]);
  const [editbranch, seteditbranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(0);
  let  memberData= useSelector((state) => state.profileSlice.profile);
  
  const loading = useSelector((state) => state.profileSlice.loading);

  useEffect(() => {
   if(memberData){
    setLocation(memberData?.data.company_branch)
   
   }
     
  
  }, [memberData]); 

  

  const handleBranchSelect = (index) => {
    setSelectedBranch(index);
  };
  

  const handleBranchDelete = async (branchId) => {
    try {
      const result = await showConfirmation('Are you sure you want to delete this branch?');
      if (result) {
        const deleteBranch = await dispatch(BranchDelete(branchId));
        if (deleteBranch.payload?.status === 'success') {
          const company_id = Cookies.get("company_id");
          const response = await dispatch(Profileget({ company_id }));
          if (response?.payload.status === 'success') {
            toast.success("Branch Deleted Successfully!");
          }
        }
      }
    } catch (error) {
      console.error('Error :', error);
    }
    

    
    
  };
  
  const handleEditClick =(data) =>{
    seteditbranch(data)
    setaboutshow(true)
  }

  const getGoogleMapsEmbedLink = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps?q=${encodedAddress}&output=embed`;
  };

  return (
    <>
    <Loading loading={loading} />
    <AboutDetails show={aboutshow} onHide={() => setaboutshow(false)} branch={editbranch} />
    <BranchDetails showModal={showModal} closeModal={closeModal} />
    <div className="container-fluid mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-lg-2 col-12 bg-theme-color rounded">
          <button className="btn btn-light bg-theme-color  mt-3 mb-4  w-100" onClick={openModal}>
              <i className="fas fa-plus"></i> Add New Branch
            </button>
            <h5 className='mt-3 mb-4'>Branches in India</h5>
            <ul className="list-unstyled mt-3">
              {Location.map((branch,index) => (
                <li key={branch.id}>
                  <button
                    onClick={() => handleBranchSelect(index)}
                    className="btn btn-light bg-theme-color mb-3 w-100 d-flex justify-content-between align-items-center"
                  >
                    {branch.country?.split(':').filter(Boolean)[1]}
                    <i
                      className="fas fa-trash-alt text-danger ms-1
                      "
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBranchDelete(branch.id);
                      }}
                    />
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-sm-8 col-md-8 col-lg-10 col-12 rounded bg-white">
            <div className="d-flex justify-content-between align-items-center">
              {/* <div className="mx-auto text-center">
                <h5 className="mb-0">You have registered {registeredOffice.office.name}.</h5>
              </div> */}
              <button className="btn btn-primary bg-theme btn-sm ms-2" onClick={() => handleEditClick(Location[selectedBranch])} >
                <i className="fas fa-pen"></i> Edit
              </button>
            </div>



           
              <div className=''>
                <h6 className='text-center' >Details for {Location[selectedBranch]?.country?.split(':').filter(Boolean)[1]}:</h6>
                <p className='ms-5 mt-3'><strong>Address:</strong> {Location[selectedBranch]?.address}</p>
                <p className='ms-5'><strong>Employees:</strong> {Location[selectedBranch]?.employee_strength}</p>
                {/* <p className='ms-5'><strong>Company Name:</strong> {selectedBranch.details.companyName}</p> */}
                <p className='ms-5'><strong>Contact Number:</strong> {Location[selectedBranch]?.country_code?.split(':').filter(Boolean)[1] +'-'+Location[selectedBranch]?.contact}</p>


                <div className="ms-5 mt-4">
                  <iframe
                    src={getGoogleMapsEmbedLink(Location[selectedBranch]?.country?.split(' ').slice(1).join(' ') || '')}
                    width="100%"
                    height="400vh"
                    allowFullScreen=""
                    loading="lazy"
                    title="Location Map"
                  ></iframe>
                </div>
              </div>
            {/* ) : (
              <p>Select a branch to view details.</p>
            )} */}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default About;
