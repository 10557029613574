import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { countrycode } from "../apis/api/DataApi";
import { Profileget, updateMemberDetails } from "../apis/api/ProfileApi";
import FileInput from "../hooks/FileInput"; 
import Cookies from "js-cookie";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Loading from "../hooks/Loading";
import { toast } from "react-toastify";

const EditDetailsofMember = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

  const [imageUrl, setImageUrl] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null); // File state

  useEffect(() => {
    dispatch(countrycode());
  }, []);

  useEffect(() => {
    fetchMemberProfile();
  }, [props]);

  const fetchMemberProfile = async () => {
    const { member } = props; 
    if (member) {
      setValue("Name", member.name);
      setValue("Title", { value: member.salutation, label: member.salutation });
      setValue("Mobile", member.contact);
      setValue("country_code", { value: member.country_code, label: member.country_code?.split(":")[1].trim() });
      setValue("email", member.email);
      setImageUrl(member.member_logo);
      setUserId(member.id);
      setLoading(false);
    }
  };

  const { country_code } = useSelector((state) => state.dataSlice);
  const countryCodeOptions = country_code.data;

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.value; 
    setFile(selectedFile); 
  };

  const onSubmitHandler = async (formData) => {
    setLoading(true);
    const data = new FormData();
    const company_id = Cookies.get("company_id");
    
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object" && formData[key] !== null) {
        Object.keys(formData[key]).forEach((subKey) => {
          data.append(`${key}[${subKey}]`, formData[key][subKey]);
        });
      } else {
        data.append(key, formData[key]);
      }
    });

    data.append("userId", userId);
    data.append("company_id", company_id);
    data.append("file", file); 

    const response = await dispatch(updateMemberDetails(data));
    if (response.payload.status === 'success') {
      await dispatch(Profileget({ company_id: company_id }));
      setLoading(false);
      props.onHide(); 
      toast.success("Profile upload successful!");
    } else {
      props.onHide(); 
      toast.error("Profile upload failed, please try again!");
    }
  };

  const selectedTitle = watch("Title");
  const selectedCountryCode = watch("country_code");

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading loading={loading} />
          <form onSubmit={handleSubmit(onSubmitHandler)} className="form-container" encType="multipart/form-data">
            <div className="row">
              <div className="col-md-6">
                <label>Title</label>
                <Select
                  options={titleOptions}
                  value={selectedTitle}
                  onChange={(selectedOption) => setValue("Title", selectedOption)}
                />
                {errors.Title && <span className="text-danger">{errors.Title.message}</span>}
              </div>

              <div className="col-md-6">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  {...register("Name", { required: "Name is required" })}
                />
                {errors.Name && <span className="text-danger">{errors.Name.message}</span>}
              </div>

              <div className="col-md-6">
                <label>Phone Number</label>
                <div className="d-flex">
                  <Select
                    options={countryCodeOptions}
                    value={selectedCountryCode}
                    onChange={(selectedOption) => setValue("country_code", selectedOption)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile"
                    {...register("Mobile", { required: "Contact is required" })}
                  />
                </div>
                {errors.Mobile && <span className="text-danger">{errors.Mobile.message}</span>}
              </div>

              <div className="col-md-6">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && <span className="text-danger">{errors.email.message}</span>}
              </div>

              <div className="col-md-6">
                
                <FileInput
                  name="file" 
                  label="Upload Member Logo"
                  handleChange={handleFileChange} 
                  defaultUrl={imageUrl}
                />
              </div>

              <div className="col-md-12">
                <button type="submit" className="btn btn-primary mt-3">Submit</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditDetailsofMember;
