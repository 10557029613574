import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Profilelist } from "../apis/api/ProfileApi";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

export default function Tags({ onChange, defaultValue = [] }) {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const fetchProfileList = async () => {
    try {
      const profileListResponse = await dispatch(Profilelist({ filter: 1 }));
      if (profileListResponse.payload.status === 'success') {
        const data = profileListResponse.payload.data;
        setList(data);
      }
    } catch (error) {
      console.error('Failed to fetch profile list:', error);
    }
  };

  useEffect(() => {
    fetchProfileList();
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
      multiple
      id="size-small-outlined-multi"
      size="small"
      limitTags={0}
      options={list}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField {...params} label="Tags" placeholder="Favorites" />
      )}
      sx={{ width: '300px' }}
    />
  );
}
