import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { statelist, countrylist, countrycode } from "../apis/api/DataApi";
import { BranchUpdate } from "../apis/api/ProfileApi";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Profileget } from "../apis/api/ProfileApi";
import Loading from "../hooks/Loading"; 

const AboutDetails = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

  useEffect(() => {
    dispatch(countrylist());
    dispatch(statelist());
    dispatch(countrycode());
  }, [dispatch]);
 
  const loading = useSelector((state) => state.profileSlice.loading);

  const { country_list, state_list } = useSelector((state) => state.dataSlice);
  const { country_code } = useSelector((state) => state.dataSlice);
  const countryCodeOptions = country_code?.data;
  const countryOptions = country_list?.data || [];
  const stateOptions = state_list?.data || {};
  const [BranchId, SetBranchId] = useState('');
  const [filteredStateOptions, setFilteredStateOptions] = useState([]);

  useEffect(() => {
    if (props.branch) {
      fetchBranch(props.branch);
    }
  }, [props.branch]);

  // Fetch branch data and set form values
  const fetchBranch = (branch) => {
  
    setValue("CityName", branch.city || "");
    setValue("Address", branch.address || "");
    setValue("Mobile", branch.contact || "");
    setValue("Strength", branch.employee_strength || "");

 
    const countryCodeValue = branch.country_code;
    const countryCodeLabel = countryCodeValue?.split(":")[1]?.trim();

    setValue("CountryCode", {
      value: countryCodeValue,
      label: countryCodeLabel,
    });

    // Set selected country name
    const countryNameValue = branch.country;
    const countryNameLabel = countryNameValue?.split(":")[1]?.trim();

    setValue("CountryName", {
      value: countryNameValue,
      label: countryNameLabel,
    });
    setValue("StateName", {
      value: branch.state,
      label: branch.state,
    });
   
    SetBranchId(branch.id);
  };


  // useEffect(() => {
  //   const selectedCountry = watch("CountryName");
  //   if (selectedCountry) {
  //     const countryKey = selectedCountry.value;


  //     const statesForCountry = stateOptions[countryKey] || [];
     
  //     const mappedStates = statesForCountry.map(state => ({
  //       value: state.value,
  //       label: state.label,
  //     }));

  //     setFilteredStateOptions(statesForCountry);
  //   } else {
  //     setFilteredStateOptions([]);
  //   }
  // }, [watch("CountryName"), stateOptions]);

  useEffect(() => {
    const selectedCountry = watch("CountryName");
  
    if (selectedCountry) {
      const countryKey = selectedCountry.value;
  
      const statesForCountry = stateOptions[countryKey] || [];
  
      const mappedStates = statesForCountry.map(state => ({
        value: state.value,
        label: state.label,
      }));
  
      // Only update state if the statesForCountry changes
      setFilteredStateOptions(mappedStates);
    } else {
      setFilteredStateOptions([]);
    }
  }, [watch("CountryName"), stateOptions]);
 
  const onSubmitHandler = async (formData) => {
    const data = new FormData();
    const company_id = Cookies.get("company_id");

    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object" && formData[key] !== null) {
        if (Array.isArray(formData[key])) {
          formData[key].forEach((item, index) => {
            if (item.value) {
              data.append(`${key}[${index}]`, item.value);
            } else {
              data.append(`${key}[${index}]`, item);
            }
          });
        } else {
          if (key === "company" && formData[key].country) {
            data.append("country", formData[key].country.value);
          } else if (key === "Company" && formData[key].state) {
            data.append("state", formData[key].state.value);
          } else if (formData[key]?.value) {
            data.append(key, formData[key].value);
          } else {
            Object.keys(formData[key]).forEach((subKey) => {
              const subItem = formData[key][subKey];
              if (subItem.value) {
                data.append(`${key}[${subKey}]`, subItem.value);
              }
            });
          }
        }
      } else {
        data.append(key, formData[key]);
      }
    });

    data.append("BranchId", BranchId);
    data.append("company_id", company_id);

    const response = await dispatch(BranchUpdate(data));

    if (response?.payload?.status === "success") {
      const company_id = Cookies.get("company_id");
      const response = await dispatch(Profileget({ company_id }));
      if (response?.payload.status === 'success') {
        props.onHide();
        toast.success("Branch Updated Successfully!");
      } else {
        toast.error("Failed to Update Branch.");
      }
    } else {
      toast.error("Failed to Update Branch.");
    }
  };

  const selectedCountryCode = watch("CountryCode");
  const selectedCountry = watch("CountryName");
  const selectedstate = watch("StateName");

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-bold">About Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Loading loading={loading} />
          <div className="row mx-1">
            <form method="post" onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="form-container">
                <div className="d-block border rounded my-2 px-2 py-0">
                  <div className="position-relative text-left">
                    <span className="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                      <span className="text-bold">About Details</span>
                    </span>
                    <div className="row">
                      <div className="row mt-2">
                        {/* Country Name */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Country Name <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={countryOptions}
                              value={selectedCountry}
                              onChange={(selectedOption) => setValue("CountryName", selectedOption)}
                            />
                            {errors.CountryName && (
                              <span className="text-danger">
                                {errors.CountryName.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* State Name */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              State Name <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={filteredStateOptions}
                              value={selectedstate}
                              onChange={(selectedOption) =>
                                setValue("StateName", selectedOption)
                              }
                              placeholder="Select a state"

                            />
                            {errors.StateName && (
                              <span className="text-danger">
                                {errors.StateName.message}
                              </span>
                            )}
                          </div>
                        </div>


                        {/* City Name */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              City Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="City Name"
                              {...register("CityName", {
                                required: "City Name is required",
                              })}
                            />
                            {errors.CityName && (
                              <span className="text-danger">
                                {errors.CityName.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Address */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Address <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Address"
                              {...register("Address", {
                                required: "Address is required",
                              })}
                            />
                            {errors.Address && (
                              <span className="text-danger">
                                {errors.Address.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Employee Strength */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Employee Strength <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Employee Strength"
                              {...register("Strength", {
                                required: "Employee Strength is required",
                              })}
                            />
                            {errors.Strength && (
                              <span className="text-danger">
                                {errors.Strength.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Mobile */}

                        <div className="col-md-6">
                <label>Phone Number</label>
                <div className="d-flex">
                  <Select
                    options={countryCodeOptions}
                    value={selectedCountryCode}
                    onChange={(selectedOption) => setValue("CountryCode", selectedOption)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile"
                    {...register("Mobile", { required: "Contact is required" })}
                  />
                </div>
                {errors.Mobile && <span className="text-danger">{errors.Mobile.message}</span>}
              </div>


                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal.Footer>
                <button type="submit" className="btn btn-secondary bg-theme-color">Submit</button>
                
              </Modal.Footer>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AboutDetails;
