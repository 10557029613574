import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { statelist, countrylist } from "../apis/api/DataApi";
import { updateCompanyDetails,Profileget } from "../apis/api/ProfileApi";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Loading from "../hooks/Loading";

const IntroEdit = (props) => {
  const dispatch = useDispatch();

  const companyId = Cookies.get("company_id");


  const loading = useSelector((state) => state.profileSlice.loading);
  const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();
  
  const handleClose = () => {
    reset();
    props.onHide(); 
    fetchMemberProfile(props.member);
  };

  useEffect(() => {
    dispatch(countrylist());
  }, [dispatch]);

  useEffect(() => {
    if (props.member) {
      console.log('check');
      fetchMemberProfile(props.member);
    }
  }, [props.member]);

  const fetchMemberProfile = async (member) => {
    setValue("Bio", member[0].bio || "");
    setValue("Company", member[0].company_name || "");
    setValue("Website", member[0].website || "");
    setValue("Address", member[0].address || "");
    setValue("Email", member[0].email || "");
    setValue("Mobile", member[0].contact || "");
    setValue("CountryCode", {
      value: member[0].country_code,
      label: member[0].country_code?.split(":")[1].trim(),
    });
   
  };

  const onSubmitHandler = async (formData) => {
    const data = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "CountryCode") {
        data.append("CountryCode", formData[key].value); // append country code value only
      } else {
        data.append(key, formData[key]);
      }
    });


    data.append("company_id", companyId);

    const response = await dispatch(updateCompanyDetails(data));

    if (response?.payload?.status === "success") {
      await dispatch(Profileget({ company_id: companyId }));
      props.onHide(); 
      toast.success("Company Profile upload successful!");
   
    } else {
      toast.error("Failed to Update About Details.");
    }
  };

  const selectedCountryCode = watch("CountryCode");
  const { country_code } = useSelector((state) => state.dataSlice);
  const countryCodeOptions = country_code?.data;
  
 

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-bold">Company Intro</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Loading loading={loading} />
          <div className="row mx-1">
            <form method="post" onSubmit={handleSubmit(onSubmitHandler)} encType="multipart/form-data">
              <div className="form-container">
                <div className="d-block border rounded my-2 px-2 py-0">
                  <div className="position-relative text-left">
                    <span className="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                      <span className="text-bold">Company Intro</span>
                    </span>
                    <div className="row mt-2">
                      {/* Bio */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Bio <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Bio"
                            {...register("Bio", { required: "Bio is required" })}
                          />
                          {errors.Bio && (
                            <span className="text-danger">
                              {errors.Bio.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Address */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Company Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Company name"
                            {...register("Company", {
                              required: "Company name is required",
                            })}
                          />
                          {errors.Company && (
                            <span className="text-danger">
                              {errors.Company.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Address"
                            {...register("Address", {
                              required: "Address is required",
                            })}
                          />
                          {errors.Address && (
                            <span className="text-danger">
                              {errors.Address.message}
                            </span>
                          )}
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Website <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Website"
                            {...register("Website", {
                              required: "Website is required",
                            })}
                          />
                          {errors.Website && (
                            <span className="text-danger">
                              {errors.Website.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Email */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Email"
                            {...register("Email", {
                              required: "Email is required",
                              pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          {errors.Email && (
                            <span className="text-danger">
                              {errors.Email.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Contact Number and Country Code */}
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            Contact Number <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex">
                            <Select
                              options={countryCodeOptions}
                              value={selectedCountryCode}
                              onChange={(selectedOption) => setValue("CountryCode", selectedOption)}
                            />
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Contact"
                              {...register("Mobile", {
                                required: "Contact No is required",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Invalid contact number",
                                },
                              })}
                            />
                          </div>
                          {errors.CountryCode && (
                            <span className="text-danger">
                              {errors.CountryCode.message}
                            </span>
                          )}
                          {errors.Mobile && (
                            <span className="text-danger">
                              {errors.Mobile.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <button type="submit" className="btn button-theme">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IntroEdit;
