import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { countrycode } from "../apis/api/DataApi";
import { Profileget, MemberInsert } from "../apis/api/ProfileApi";
import FileInput from "../hooks/FileInput";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import Loading from "../hooks/Loading";

const Addmember = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
 
  const [file, setFile] = useState(null); 

  useEffect(() => {
    dispatch(countrycode())
  }, [dispatch]);

  const { country_code } = useSelector((state) => state.dataSlice);
  const countryCodeOptions = country_code?.data || [];

  const loading = useSelector((state) => state.profileSlice.loading);

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.value; 
    setFile(selectedFile); 
  };

  const onSubmitHandler = async (formData) => {
    const data = new FormData();

    const company_id = Cookies.get("company_id");
    const registration_type = Cookies.get("sorce_type");

    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object" && formData[key] !== null) {
        Object.keys(formData[key]).forEach((subKey) => {
          data.append(`${key}[${subKey}]`, formData[key][subKey]);
        });
      } else {
        data.append(key, formData[key]);
      }
    });

    if (file) {
      data.append("file", file); 
    }
    
    data.append("company_id", company_id);
    data.append("registration_type", registration_type);

    const response = await dispatch(MemberInsert(data));

    if (response?.payload?.status === "success") {
      await dispatch(Profileget({ company_id: company_id }));
      props.onHide(); 
      toast.success("Member Inserted Successfully!");
      reset(); 
      setFile(null); 
    } else {
      toast.error("Failed to Update Member Details.");
    }
  };

  const handleClose = () => {
      reset();
      props.onHide(); 
  };



  return (
    <>
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // onHide={props.onHide}
          onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-bold">
              Add New Member
              <span className="form-title no-border"></span>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading loading={loading} />
          <div className="row mx-1">
            <form method="post" onSubmit={handleSubmit(onSubmitHandler)} encType="multipart/form-data">
              <div className="form-container">
                <div className="d-block border rounded my-2 px-2 py-0">
                  <div className="position-relative text-left">
                    <span className="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                      <span className="text-bold">
                        Member Information
                        <span className="form-title no-border"></span>
                      </span>
                    </span>
                    <div className="row">
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Title <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={titleOptions}
                              onChange={(selectedOption) => setValue("Title", selectedOption.value)}
                            />
                            {errors.Title && (
                              <span className="text-danger">{errors.Title.message}</span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Name"
                              {...register("Name", { required: "Name is required" })}
                            />
                            {errors.Name && (
                              <span className="text-danger">{errors.Name.message}</span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Address <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Address"
                              {...register("Address", { required: "Address is required" })}
                            />
                            {errors.Address && (
                              <span className="text-danger">{errors.Address.message}</span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Phone Number Mobile <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex">
                              <Select
                                options={countryCodeOptions}
                                onChange={(selectedOption) => setValue("country_code", selectedOption.value)}
                                className="country-code-select h-1rem"
                              />
                              <input
                                type="text"
                                className="form-control border border-grey border-1 rounded-1"
                                placeholder="Contact"
                                {...register("Mobile", { required: "Contact No is required" })}
                              />
                            </div>
                            {errors.country_code && (
                              <span className="text-danger">{errors.country_code.message}</span>
                            )}
                            {errors.Mobile && (
                              <span className="text-danger">{errors.Mobile.message}</span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Email address <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Email address"
                              {...register("email", { required: "Email is required" })}
                            />
                            {errors.email && (
                              <span className="text-danger">{errors.email.message}</span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <FileInput
                            name="file" 
                            label="Member Logo"
                            handleChange={handleFileChange} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <button type="submit" className="btn button-theme">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Addmember;
