import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { galleryInsert } from "../apis/api/GalleryApi";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../hooks/Loading";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { galleryGet } from "../apis/api/GalleryApi";

const AddAlbum = ({ showModal, onClose }) => {
  const dispatch = useDispatch();
  const [mediaArray, setMediaArray] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const user_id = Cookies.get("userId");
  const company_id = Cookies.get("company_id");
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const maxLength = parseInt(e.target.getAttribute("data-max_length"));

    const newMedia = files.filter((file) => {
      const isImage = file.type.match("image.*");
      const isVideo = file.type.match("video.*");
      return (isImage || isVideo) && mediaArray.length < maxLength;
    });

    setMediaArray((prevArray) => {
      const existingNames = prevArray.map((media) => media.name);
      return [
        ...prevArray,
        ...newMedia.filter((media) => !existingNames.includes(media.name)),
      ];
    });
  };

  const handleRemoveMedia = (fileName) => {
    setMediaArray((prevArray) =>
      prevArray.filter((media) => media.name !== fileName)
    );
  };

  const loading = useSelector((state) => state.gallerySlice.loading);

  const onSubmitHandler = async (formData) => {
    console.log(formData);

    const data = new FormData();
    data.append("albumTitle", formData.title);
    data.append("user_id", user_id);
    data.append("company_id", company_id);

    mediaArray.forEach((media) => {
      data.append("Files", media); // Append the actual file object
    });

    const response = await dispatch(galleryInsert(data));
    if (response?.payload?.status === "success") {
      // const user_id = Cookies.get("userId");
      // const company_id = Cookies.get("company_id");
     
  
      const response=await dispatch(galleryGet({ user_id, company_id }));

      if (response?.payload?.status === "success") {
        reset();
        setMediaArray([]);
        onClose();
        toast.success("Album upload successful!");
      }

     
    } else {
      toast.error("Failed to upload the album.");
    }
  };

  const closemodel =()=>{
    reset();
    setMediaArray([]);
    onClose();
  }

  return (
    <>
      {showModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show d-block"
            tabIndex="-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Upload Images and Videos</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closemodel}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Loading loading={loading} />
                  <form
                    method="post"
                    onSubmit={handleSubmit(onSubmitHandler)}
                    encType="multipart/form-data"
                  >
                    <div className="mb-3">
                      <label htmlFor="albumTitle" className="form-label">
                        Album Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter album title"
                        {...register("title", {
                          required: "Album title is required",
                        })}
                      />
                      {errors.title && (
                        <span className="text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </div>
                    <div className="upload__box">
                      <div className="upload__btn-box">
                        <label className="upload__btn">
                          <FontAwesomeIcon
                            icon={faUpload}
                            className="upload__icon"
                          />
                          <p>Choose Images/Videos</p>
                          <input
                            type="file"
                            multiple
                            data-max_length="20"
                            className="upload__inputfile"
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                      <div className="upload__img-wrap d-flex flex-wrap mt-3">
                        {mediaArray.map((media, index) => (
                          <div
                            key={index}
                            className="upload__img-box me-2 mb-2"
                            style={{ width: "30%" }}
                          >
                            <div
                              className="position-relative"
                              style={{
                                width: "100%",
                                height: "100px",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            >
                              {media.type.startsWith("image") ? (
                                <div
                                  className="img-bg"
                                  style={{
                                    backgroundImage: `url(${URL.createObjectURL(
                                      media
                                    )})`, // Display image using object URL
                                    width: "100%",
                                    height: "100px",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                />
                              ) : media.type.startsWith("video") ? (
                                <video
                                  width="100%"
                                  height="100px"
                                  controls
                                  style={{ objectFit: "cover" }}
                                >
                                  <source
                                    src={URL.createObjectURL(media)}
                                    type={media.type}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : null}

                              <button
                                className="upload__img-close btn btn-danger btn-sm position-absolute top-0 end-0"
                                onClick={() => handleRemoveMedia(media.name)}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Upload Album
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddAlbum;
