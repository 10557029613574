import React, { useState, useEffect } from "react";
import useFormValidation from "../hooks/useFormValidation";
import FileInput from "../hooks/FileInput";
import { useSelector, useDispatch } from "react-redux";
import {countrylist,statelist,membershipPlan,countrycode,} from "../apis/api/DataApi";
import { ProfileInsert } from "../apis/api/ProfileApi";
import Select from "react-select";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();

  useEffect(() => {
    dispatch(countrylist());
    dispatch(statelist());
    dispatch(membershipPlan());
    dispatch(countrycode());
  }, [dispatch]);

  const { country_list, state_list, memberplan_list, country_code } =useSelector((state) => state.dataSlice);
  const { insert } =useSelector((state) => state.profileSlice);
  const data = state_list?.data || [];
  const countryOptions = country_list?.data || [];
  const membership = memberplan_list?.data || [];
  const countryCodeOptions = country_code?.data || [];

  const { formData, errors, handleChange, handleSubmit } = useFormValidation({},validate);
  
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    if (selectedCountry) {
      setStates(data[selectedCountry]);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.value);
    handleChange({
      target: { name: "Company[country]", value: selectedOption.value },
    });
  };

  const handleStateChange = (selectedOption) => {
    handleChange({
      target: { name: "Company[state]", value: selectedOption.value },
    });
  };

  const handlemembership = (selectedOption) => {
    handleChange({
      target: { name: "Company[membershipPlan]", value: selectedOption.value },
    });
  };

  const memberCountryCode = (selectedOption) => {
    handleChange({
      target: { name: "member[CountryCode]", value: selectedOption.value },
    });
  };

  const companyCountryCode = (selectedOption) => {
    handleChange({
      target: { name: "Company[CountryCode]", value: selectedOption.value },
    });
  };

  const handlehours = (selectedOption) => {
    handleChange({
      target: { name: "Company[businessHours]", value: selectedOption.value },
    });
  };



  const generateHoursOptions = () => {
    const hours = [];
    for (let hour = 0; hour < 24; hour++) {
      const hourPadded = String(hour).padStart(2, "0");
      hours.push({
        value: hourPadded,
        label: `${hourPadded}:00`,
      });
    }
    return hours;
  };

  const hoursOptions = generateHoursOptions();

  const [selectedServices, setSelectedServices] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedServices;
    if (checked) {
      updatedServices = [...selectedServices, value];
    } else {
      updatedServices = selectedServices.filter((service) => service !== value);
    }

    setSelectedServices(updatedServices);
    handleChange({
      target: { name: "generalServices", value: updatedServices },
    });
  };

 
  const onSubmit = async (formData) => {
    const userId = Cookies.get('userId');
  
    const data = new FormData();
    if (userId) {
      data.append('userId', userId);
    }

    Object.keys(formData).forEach(key => {
      if (typeof formData[key] === 'object' && formData[key] !== null) {
        Object.keys(formData[key]).forEach(subKey => {
          data.append(`${key}[${subKey}]`, formData[key][subKey]);
        });
      } else {
        data.append(key, formData[key]);
      }
    });

    try {
      const response = await dispatch(ProfileInsert(data));
      if (response.payload.status === 'success') {
        const member_detail = JSON.stringify(response.payload.member_Details);
        Cookies.set("member_detail", member_detail, { expires: 7 });
        navigate("/home")
      }
    } catch (error) {
      console.error("Error dispatching ProfileInsert:", error);
    }
  };
  const customStyles = {
    control: (provided) => ({
        ...provided,
        height: '1.8rem', // Adjust the height as needed
        
    }),}


  return (
    <div className="main-content mt-2">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <form method="post" onSubmit={(e) => handleSubmit(e, onSubmit)}>
         
         
              <div className="mb-4">
                <span className="d-block text-md text-bold font-weight-bold underline">
                  COMPANY INFORMATION:{" "}
                </span>
              </div>
              <div className="">
                <p
                  className="bg-template text-white card-title p-1 bg-marble"
                  style={{ width: "84px", height: "30px" }}
                >
                  Company
                </p>
                <div className="row p-2">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Company Name </label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="your company Name"
                        name="Company[company_name]"
                        value={formData.Company?.company_name}
                        onChange={handleChange}
                      />
                      {errors.Company?.company_name && (
                        <span className="text-danger">
                          {errors.Company.company_name}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 Removefocus">
                    <FileInput
                   
                      name="Company[company_logo]"
                      label="Company Logo"
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Telephone</label>
                      <div className="d-flex Removefocus">
                        <Select
                          options={countryCodeOptions}
                          onChange={companyCountryCode}
                          className="country-code-select  h-1rem"
                        />
                        <input
                          type="text"
                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                          placeholder="contact"
                          name="Company[telephone]"
                          value={formData.Company?.telephone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6  Removefocus">
                    <FileInput
                      name="Company[company_banner]"
                      label="Company Banner"
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row p-2">
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Country</label>
                      <Select
                        options={countryOptions}
                        onChange={handleCountryChange}
                        placeholder={"select the country"}
                      />
                      {errors.country && (
                        <span className="text-danger">{errors.country}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">State</label>
                      <Select
                        options={states}
                        onChange={handleStateChange}
                        placeholder={"select the state"}
                      />
                      {errors.state && (
                        <span className="text-danger">{errors.state}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">City</label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="your City"
                        name="Company[city]"
                        value={formData.Company?.city}
                        onChange={handleChange}
                      />
                      {errors.Company?.city && (
                        <span className="text-danger">
                          {errors.Company.city}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Website</label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="your website"
                        name="Company[website]"
                        value={formData.Company?.website}
                        onChange={handleChange}
                      />
                      {errors.Company?.website && (
                        <span className="text-danger">
                          {errors.Company.website}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Company Email</label>
                      <input
                        type="email"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="Company email"
                        name="Company[email]"
                        value={formData.Company?.email}
                        onChange={handleChange}
                      />
                      {errors.Company?.email && (
                        <span className="text-danger">
                          {errors.Company.email}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Fax</label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="fax"
                        name="Company[fax]"
                        value={formData.Company?.fax}
                        onChange={handleChange}
                      />
                      {errors.Company?.fax && (
                        <span className="text-danger">
                          {errors.Company.fax}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Zip Code</label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="zip code"
                        name="Company[zipcode]"
                        value={formData.Company?.zipcode}
                        onChange={handleChange}
                      />
                      {errors.Company?.zipcode && (
                        <span className="text-danger">
                          {errors.Company.zipcode}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label ">Membership Plan</label>
                      <Select
                      className="Removefocus"
                        options={membership}
                        onChange={handlemembership}
                        placeholder={"select the membership plan"}
                      />
                      {errors.membershipPlan && (
                        <span className="text-danger">
                          {errors.membershipPlan}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 Removefocus ">
                    <div className="form-group mb-4">
                      <label className="form-label">
                        Business/Trading Hours
                        <span className="text-red">*</span>
                      </label>
                      <Select
                      className="Removefocus"
                        options={hoursOptions}
                        onChange={handlehours}
                        placeholder={"select the membership plan"}
                      />
                      {/* {errors.membershipPlan && (
                        <span className="text-danger">
                          {errors.membershipPlan}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-4">
                      <label className="form-label">Address</label>
                      <textarea
                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                        placeholder="address"
                        name="Company[address]"
                        value={formData.Company?.address}
                        onChange={handleChange}
                      ></textarea>
                      {errors.Company?.address && (
                        <span className="text-danger">
                          {errors.Company.address}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form-row ml-auto">
                    <div className="form-group col-xl-12 mt-3">
                      <label>
                        General Forwarding Services:{" "}
                        <span className="text-red">
                          * (Please kindly specify your services by choosing
                          from the below)
                        </span>
                      </label>
                      <div className="form-check">
                        <div className="row">
                          {[
                            "Ocean Freight - FCL",
                            "Trucking",
                            "Project services",
                            "E-commerce",
                            "Warehousing",
                            "Custom Brokerage",
                            "Distribution",
                            "Consolidation",
                            "Breakbulk",
                          ].map((service, index) => (
                            <div
                              key={index}
                              className="check-box form-check-block col-md-6 mb-2"
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="Company[services]"
                                value={service}
                                onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label">
                                {service}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <button type="submit" className="btn button-theme">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;

function validate(formData) {

  const errors = {};
  // if (!formData.member?.firstName) {
  //   errors.member = { ...errors.member, firstName: "First name is required" };
  // }
  // if (!formData.member?.lastName) {
  //   errors.member = { ...errors.member, lastName: "Last name is required" };
  // }

  // if (!formData.member?.contact) {
  // errors.member = { ...errors.member, contact: "Contact is required" };
  // }

  // if (!formData.member?.email) {
  //   errors.member = { ...errors.member, email: "Email is required" };
  // }

  if (!formData.Company?.company_name) {
    errors.Company = {
      ...errors.Company,
      company_name: "Company name is required",
    };
  }

  // if (!formData.Company?.telephone) {
  // errors.Company = { ...errors.Company, telephone: "Company telephone is required" };
  // }

  if (!formData.Company?.country) {
    // errors.country = { ...errors.Company, country: "country is required" };
    errors.country = "Country is required";
  }

  if (!formData.Company?.state) {
    errors.state = "State is required";
  }

  if (!formData.Company?.membershipPlan) {
    errors.membershipPlan = "Membership plan is required";
  }

  // if (!formData.Company?.description) {
  // errors.description = "Description is required";
  // }
  return errors;
}
