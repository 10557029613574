import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { countrylist_url,statelist_url,BASE_URL ,NODE_URL} from "./Config";


export const countrylist = createAsyncThunk(
    "get/countrylist",
    async () => { 
        let url = countrylist_url
        try{
            
            const response = (await axios.get(url)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const statelist = createAsyncThunk(
    "get/statelist",
    async () => { 
        let url = statelist_url
        try{
            
            const response = (await axios.get(url)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const membershipPlan = createAsyncThunk(
    "post/membershipPlan",
    async (data) => { 
        let url = BASE_URL+"data/membership"
        try{
            
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const membershipPlanlist = createAsyncThunk(
    "post/membershipPlanlist",
    async (data) => { 
        let url = BASE_URL+"data/Membershiplanlist"
        try{
            
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)


export const countrycode = createAsyncThunk(
    "get/countrycode",
    async () => { 
        let url = BASE_URL+"data/country/countrycode"
        try{
            
            const response = (await axios.get(url)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           // console.log("getMainApps Error:", error)
            return Promise.reject(error)
        }
    }
)

export const services = createAsyncThunk(
    "get/services",
    async () => { 
        let url = NODE_URL+'services'
        try{
            
            const response = (await axios.get(url)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

