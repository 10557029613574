
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { galleryGet } from "../apis/api/GalleryApi";
import { useNavigate, Link } from "react-router-dom";
// import Cookies from "js-cookie";
import AlbumCard from "./albumcard";
import AddAlbum from "./AddAlbum";

function AlbumsList() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  let galleryData = useSelector((state) => state.gallerySlice.gallery);
  galleryData = galleryData?.data || [];

  // useEffect(() => {
  //   const user_id = Cookies.get("userId");
  //   const company_id = Cookies.get("company_id");
  //   dispatch(galleryGet({ user_id, company_id }));
  // }, [dispatch]);

  const handleAlbumClick = (albumId) => {
    navigate(`/album/${albumId}`);
  };

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <AddAlbum showModal={showModal} onClose={handleCloseModal} />

      <div className="col d-flex justify-content-between mt-3">
        <button
          className="btn btn-secondary bg-theme btn-sm mb-3"
          onClick={handleShowModal}
        >
          <i className="fas fa-plus me-2"></i>Add Album
        </button>
        <Link to="/album" className="btn btn-secondary bg-theme btn-sm mb-3">
          <i className="me-2"></i>View Album
        </Link>
      </div>

      <div className="row">
        <div className="container">
          <div className="row">
            {galleryData.map((album) => (
              <AlbumCard
                key={album.id}
                album={album}
                onClick={() => handleAlbumClick(album.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AlbumsList;
