import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { CommunityAll } from "../apis/api/CommunityApi";
import { useDispatch, useSelector } from "react-redux";
import { FILE_URL } from "../apis/api/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import {
  faEye,
  faEdit,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const MyDataTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const dispatch = useDispatch();
  const sourceType = Cookies.get("sorce_type");

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(CommunityAll({ source_type: sourceType }));
    };
    fetchData();
  }, [dispatch, sourceType]);

  const { community } = useSelector((state) => state.communitySlice) || {};

  useEffect(() => {
    if (community && community.data) {
      const filteredData = community.data.filter((customer) =>
        customer.company_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProfiles(filteredData);
    }
  }, [searchQuery, community]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectionChange = (e) => {
    setSelectedProfiles(e.value);
  };

  const nameBodyTemplate = (rowData) => (
    <div className="d-flex align-items-center">
      <img
        src={
          rowData?.company_logo && rowData.company_logo !== ""
            ? `${FILE_URL}/${rowData.company_logo}`
            : `/images.jpeg` 
        }
        alt={rowData.company_name || "Default Logo"}
        style={{
          width: "30px",
          height: "30px",
          marginRight: "10px",
          borderRadius: "50%",
        }}
      />

      {rowData.company_name}
    </div>
  );

  const activityBodyTemplate = () => (
    <div className="d-flex justify-content-around me-2">
      <button className="btn btn-primary me-1 p-0">
        <FontAwesomeIcon icon={faEye} className="text-white p-1" />
      </button>
    </div>
  );

  const handleRowsChange = (e) => {
    setRowsPerPage(Number(e.target.value));
  };

  return (
    <div className="datatable-crud-demo container-fluid ">
      <h5 className="mb-2 mt-3 text-color member-list-heading">
        {" "}
        <i class="fas fa-users me-1 "></i>Member List
      </h5>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <div className="input-group" style={{ maxWidth: "300px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search by name..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="btn btn-primary bg-theme">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <select
          className="form-select ms-2"
          value={rowsPerPage}
          onChange={handleRowsChange}
          style={{ width: "100px" }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>

      <DataTable
        value={filteredProfiles}
        paginator
        rows={rowsPerPage}
        emptyMessage="No members found."
        rowClassName={() => "custom-row"}
        selection={selectedProfiles}
        onSelectionChange={onSelectionChange}
        selectionMode="checkbox"
      >
        <Column
          selectionMode="multiple"
          headerClassName="bg-theme-color me-2"
          headerStyle={{
            width: "2rem",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "30px" }}
        ></Column>

        <Column
          body={nameBodyTemplate}
          header="Name"
          headerClassName="bg-theme-color ms-2"
          headerStyle={{ paddingLeft: "15px", paddingRight: "30px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "30px" }}
          sortable
        />

        <Column
          field="address"
          header="Address"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="country"
          header="Country"
          body={(rowData) => rowData.country || ""}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="member_plan"
          header="Membership Plan"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="membership_start"
          header="Start Date"
          body={(rowData) =>
            new Date(rowData.membership_start).toLocaleDateString()
          }
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="status"
          header="Status"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="activity"
          header="Activity"
          body={activityBodyTemplate}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
        />
      </DataTable>
    </div>
  );
};

export default MyDataTable;
