import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './hooks/ProtectedRoute';
import ProtectedOtp from './hooks/ProtectedOtp';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Signup from './Authentication/Signup';
import Signin from './Authentication/Signin';
import Thankuou from './Authentication/Thankyou';
import Main from './feeds/Main2';
import MainNavFooter from './layout/MainNavFooter';
import Registration from './forms/Registration';
import OTP from './Authentication/OTP';
import News from './news/News';
import MemberRegistration from './forms/MemberRegistration';
import MembershipPlan from './membership/Membership';
import './App.css';
import Loading from './hooks/Loading';
import MemberDetails from './Profile/MemberDetail';
import Dashboard from './dashboard/dashboard';
import Team from "./Managemember/ManageTeams";
import EditDetails from  './forms/EditDetailsofMember';
import Sidebar from './layout/SidebarWithMainLayout';
import AboutDetails from './Aboutdetail/AboutDetail';
import Community from './Communitiy/Community';
import ActiveMemberTable from './Tables/ActiveMemberTable';
import ALLNewsTable from './Tables/ALLNewsTable';
import Album from"./album/Album"
import OtherMember from './Profile/OtherMembers';
import AlbumDetails from"./album/Albumdetails";
import BranchDetailsAndServices from "./forms/BranchDetailsAndServices"

function AppContent() {
  const userId = Cookies.get('userId');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    console.log("check");
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      <Loading loading={loading} />
      <Routes>
        <Route path="/" element={userId ? <Navigate to="/home" /> : <Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/thankyou" element={<Thankuou />} />
        <Route path="/services" element={<BranchDetailsAndServices/>} /> 
        <Route path="/registration/:plan/:id" element={<MemberRegistration />} />
        <Route path="/registration/:id" element={<MemberRegistration />} />
        
        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/feeds" element={<Sidebar><Main /></Sidebar>} />
          <Route path="/member" element={<MainNavFooter><Registration /></MainNavFooter>} />
          <Route path="/news" element={<Sidebar><News /></Sidebar>} />
          <Route path="/home" element={<Sidebar><Dashboard /></Sidebar>} />
          <Route path="/membership" element={<Sidebar><MembershipPlan /></Sidebar>} />
          <Route path="/member/:id" element={<Sidebar><OtherMember/></Sidebar>} />
          <Route path="/Team" element={<Sidebar><Team/></Sidebar>} />
          <Route path="/profile" element={<Sidebar><MemberDetails/></Sidebar>} />
          <Route path="/edit" element={<Sidebar><EditDetails/></Sidebar>} /> 
          <Route path="/about" element={<Sidebar><AboutDetails/></Sidebar>} /> 
          <Route path="/community" element={<Sidebar><Community/></Sidebar>} /> 
          <Route path="/activeMember" element={<Sidebar><ActiveMemberTable/></Sidebar>} /> 
          <Route path="/allnews" element={<Sidebar><ALLNewsTable/></Sidebar>} /> 
          <Route path="/album" element={<Sidebar><Album/></Sidebar>} /> 
          <Route path="/album/:albumId" element={<Sidebar><AlbumDetails/></Sidebar>} /> 
          
         
        
        </Route>
        
        {/* Protected OTP route */}
        <Route element={<ProtectedOtp />}>
          <Route path="/otp" element={<OTP />} />
        </Route>
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;