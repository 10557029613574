import { createSlice } from "@reduxjs/toolkit";
import { FeedsInsert, FeedsGet,MemberFeedsGet ,FeedsLikes, insertcomment ,fetchcomments,fetchSearchDetails} from "../api/FeedsApi";

const feedsSlice = createSlice({
    name: "feedsSlice",
    initialState: {
      loading: false,
      feeds_insert: null,
      feeds_get:null,
      feeds_like:null,
      comment_insert:null,
      comment_get:'',
      member_feeds:'',
      fetchSearchDetails:null
    },
    reducers:{
    },

    extraReducers: (builder) => {
      builder.addCase(FeedsInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(FeedsInsert.fulfilled,  (state, action) => {
       state.feeds_insert=action.payload;
        state.loading = false
       
      })
      builder.addCase(FeedsInsert.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })


      builder.addCase(FeedsGet.pending, state => {
        state.loading = true
      })

      builder.addCase(FeedsGet.fulfilled,  (state, action) => {
       state.feeds_get=action.payload;
        state.loading = false
       
      })
      
      builder.addCase(FeedsGet.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

     //MemberFeedsGet

      builder.addCase(MemberFeedsGet.pending, state => {
        state.loading = true
      })

      builder.addCase(MemberFeedsGet.fulfilled,  (state, action) => {
       state.member_feeds=action.payload;
     
        state.loading = false
       
      })
      
      builder.addCase(MemberFeedsGet.rejected, (state,action) => {
        console.log("rejected")
        state.loading = false
      })

      builder.addCase(FeedsLikes.fulfilled,  (state, action) => {
       state.feeds_like=action.payload;

        
      })
      
      builder.addCase(FeedsLikes.rejected, (state,action) => {
        console.log("rejected")
       
      })

// insert-comment-slice---
      builder.addCase(insertcomment.pending, state => {
        // state.loading = true
      })
      builder.addCase(insertcomment.fulfilled,  (state, action) => {
       state.comment_insert=action.payload;
        // state.loading = false
       
      })
      builder.addCase(insertcomment.rejected, (state,action) => {
        console.log("rejected")
        // state.loading = false
      })


// get-comment-slice---
builder.addCase(fetchcomments.pending, state => {
  // state.loading = true
})
builder.addCase(fetchcomments.fulfilled,  (state, action) => {
 state.comment_get=action.payload;
  state.loading = false
 
})
builder.addCase(fetchcomments.rejected, (state,action) => {
  console.log("rejected")
  // state.loading = false
})

builder.addCase(fetchSearchDetails.pending, state => {
  // state.loading = true
})
builder.addCase(fetchSearchDetails.fulfilled,  (state, action) => {
 state.comment_get=action.payload;
  state.loading = false
 
})
builder.addCase(fetchSearchDetails.rejected, (state,action) => {
  console.log("rejected")
  // state.loading = false
})
   

    
    }
  })
  export default feedsSlice.reducer;


  


