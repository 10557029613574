
import React from "react";

function AlbumCard({ album, onClick }) {
  return (
    <div className="col-md-3 mb-3">
      <div className="album-card" onClick={onClick}>
        <div className="cover-photos">
          {album.file_url.slice(0, 4).map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`Cover ${index + 1}`}
              className="cover-photo"
            />
          ))} 
        </div>
        {/* <p className="album-name bg-theme-2 pt-3 pb-3 mb-0">{album.title}</p> */}
      </div>
    </div>
  );
}

export default AlbumCard;
