import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slice/AuthSlice";
import dataReducer from "../slice/DataSlice";
import profileReducer from "../slice/ProfileSlice";
import newsReducer from "../slice/NewsSlice";
import feedsReducer from "../slice/FeedsSlice";
import dashboardReducer from "../slice/DashboardSlice";
import membershipReducer from "../slice/MembershipSlice";
import communityReducer from "../slice/CommunitySlice";
import galleryReducer from "../slice/GallerySlice";


export const Store = configureStore({
    reducer:{
        authSlice:authReducer,
        dataSlice:dataReducer,
        profileSlice:profileReducer,
        newsSlice:newsReducer,
        feedsSlice:feedsReducer,
        dashboardSlice:dashboardReducer,
        membershipSlice:membershipReducer,
        communitySlice:communityReducer,
        gallerySlice:galleryReducer,
    }
});
