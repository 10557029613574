// components/ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = () => {
  // const userId = localStorage.getItem('userId');
  const userId = Cookies.get('userId');

  const location = useLocation();

  if (userId && location.pathname === '/') {
    return <Navigate to="/Home" />;
  }


  return userId ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;