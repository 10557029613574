import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { NODE_URL,BASE_URL} from "./Config";
// import Cookies from 'js-cookie';



export const galleryInsert = createAsyncThunk(
    "post/galleryInsert",
    async (data) => { 
        let url = NODE_URL+'galleryInsert';
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const galleryGet = createAsyncThunk(
    "post/galleryGet",
    async (data) => { 
        let url = NODE_URL+'galleryGet';
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)





