import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { NODE_URL,BASE_URL} from "./Config";
import Cookies from 'js-cookie';



export const activeMember = createAsyncThunk(
    "get/activeMember",
    async () => { 
        let url = NODE_URL+'ActiveMember';
        const token=Cookies.get("token");
        try{
            
            const response = (await axios.get(url,{
                
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
            }

            )).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const latestMember = createAsyncThunk(
    "get/latestMember",
    async () => { 
        let url = NODE_URL+'latestMember';
        const token=Cookies.get("token");
        try{
            
            const response = (await axios.get(url,{
                
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
            }

            )).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const Magazine = createAsyncThunk(
    "get/Magazine",
    async () => { 
        let url = NODE_URL+'Magazine';
        const token=Cookies.get("token");
        try{
            
            const response = (await axios.get(url,{
                
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
            }

            )).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const NewsAll = createAsyncThunk(
    "post/NewsAll",
    async (data) => { 
        let url = NODE_URL+'NewsAll';
        const token=Cookies.get("token");
        try{
            
            const response = (await axios.post(url,data,{
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
            }

            )).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const profileAll = createAsyncThunk(
    "get/profileAll",
    async (data) => { 
        let url = NODE_URL+'profileAll';
        const token=Cookies.get("token");
        try{
            
            const response = (await axios.get(url,{
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
            }

            )).data
            if(response.status == "success"){
                console.log("ggggggggggggggggg",response)
                return Promise.resolve(response)

            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)




