// ConfirmDialog.js
import React from 'react';

const ConfirmDialog = ({ show, message, onConfirm, onCancel }) => {
  if (!show) return null; // Do not render if `show` is false

  return (
    <div className="dialog-overlay">
      <div className="dialog-content">
        <p>{message}</p>
        <button onClick={onConfirm}>Confirm</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ConfirmDialog;
