import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { useLocation } from "react-router-dom";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FILE_URL } from "../apis/api/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const ALLNewsTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const location = useLocation();
  const { newsData } = location.state || {};

 

  useEffect(() => {
    if (newsData) {
      const filteredData = newsData.data.filter((customer) =>
        customer.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProfiles(filteredData);
    }
  }, [searchQuery, newsData]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectionChange = (e) => {
    setSelectedProfiles(e.value);
  };

  const nameBodyTemplate = (rowData) => (
    <div className="d-flex align-items-center">
      <img
        src={`${FILE_URL}/${rowData?.image || "default-logo.png"}`}
        alt={rowData.title}
        style={{
          width: "5vw",
          height: "5vh",
          marginRight: "10px",
          borderRadius: "5%",
        }}
      />
     
    </div>
  );

  const activityBodyTemplate = () => (
    <div className=" me-2">
      <button className="btn btn-primary me-1 p-0">
        <FontAwesomeIcon icon={faEye} className="text-white p-1" />
      </button>
    </div>
  );

  const handleRowsChange = (e) => {
    setRowsPerPage(Number(e.target.value));
  };

  return (
    <div className="datatable-crud-demo container-fluid ">
      <h5 className="mb-2 mt-3 text-color  d-flex align-items-center">
        <i className="fas fa-users me-1"></i>
       
      News Details
      </h5>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <div className="input-group" style={{ maxWidth: "300px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search by name..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="btn btn-primary bg-theme">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <select
          className="form-select ms-2"
          value={rowsPerPage}
          onChange={handleRowsChange}
          style={{ width: "100px" }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>

      <DataTable
        value={filteredProfiles}
        paginator
        rows={rowsPerPage}
        emptyMessage="No members found."
        rowClassName={() => "custom-row"}
        selection={selectedProfiles}
        onSelectionChange={onSelectionChange}
        selectionMode="checkbox"
      >
        <Column
          selectionMode="multiple"
          headerClassName="bg-theme-color me-2 "
          headerStyle={{ width: "2 rem" }}
        ></Column>

        <Column
          body={nameBodyTemplate}
          header="News"
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="title"
          header="Title"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

       

        <Column
          field="date"
          header="Date"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="activity"
          header="Activity"
          body={activityBodyTemplate}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
        />
      </DataTable>
    </div>
  );
};

export default ALLNewsTable;
