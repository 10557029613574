import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { services } from "../apis/api/DataApi";
import { ServiceUpdate } from "../apis/api/ProfileApi";
import Modal from 'react-bootstrap/Modal';
import Loading from "../hooks/Loading"; 
import Cookies from "js-cookie";
import { Profileget } from "../apis/api/ProfileApi";


const ServicesForm = ({ showModal, handleClose,data }) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    dispatch(services());
  }, [dispatch]);

  const service_data = useSelector(
    (state) => state.dataSlice.service?.data || {}
  );
  const loading = useSelector((state) => state.profileSlice.loading);
  
  useEffect(() => {
   
    if (data && Array.isArray(data.basic_services) && Array.isArray(data.special_services)) {
      
     
      setValue(
        "base_service",
        data.basic_services.map((service) => ({
          label: service.name,
          value: service.id,
        }))
      );
      
     
      setValue(
        "special_service",
        data.special_services.map((service) => ({
          label: service.name,
          value: service.id,
        }))
      );
    }
  }, [data, setValue]);
  

  const onSubmitHandler = async (formData) => {
    const formattedData = new FormData();
    const companyId = Cookies.get("company_id");

    const baseServices = formData.base_service.map(service => service.value);
    formattedData.append("base_service", baseServices);
  
    const specialServices = formData.special_service.map(service => service.value);
    formattedData.append("special_service", specialServices);
    formattedData.append("company_id", companyId);
  
  
    for (let [key, value] of formattedData.entries()) {
      
    }
  
    const response = await dispatch(ServiceUpdate(formattedData));
    
    if (response?.payload?.status === "success") {
      const profile=await dispatch(Profileget({ company_id: companyId }))
      if(profile.payload.status=='success'){
        toast.success("Services Updated Successfully!");
        handleClose(); 
        reset(); 
      }
    
    } else {
      toast.error("Failed to Update Services.");
    }
  };

  const CloseModel = () => {
    reset();
    handleClose(); 
    
  };
  
  

  return (
    <Modal
      show={showModal}
      onHide={CloseModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-bold">Services</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Loading loading={loading} />
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="form-container">
            <div className="d-block border rounded my-2 px-2 py-0">
              <div className="position-relative text-left">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Base Services <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="base_service"
                        control={control}
                        rules={{ required: "Service is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={service_data.base_service}
                            isMulti
                            onChange={(selectedOptions) => {
                              field.onChange(selectedOptions);
                              setValue("base_service", selectedOptions);
                            }}
                            placeholder="Select services"
                          />
                        )}
                      />
                      {errors.base_service && (
                        <span className="text-danger">
                          {errors.base_service.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Special Services <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="special_service"
                        control={control}
                        rules={{ required: "Special service is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={service_data.special_service}
                            isMulti
                            onChange={(selectedOptions) => {
                              field.onChange(selectedOptions);
                              setValue("special_service", selectedOptions);
                            }}
                            placeholder="Select special services"
                          />
                        )}
                      />
                      {errors.special_service && (
                        <span className="text-danger">
                          {errors.special_service.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4 text-center">
            <button type="submit" className="btn bg-theme-color">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ServicesForm;
